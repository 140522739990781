import {User} from '../../shared/user/user.model';
import {PieceJointe} from '../piece-jointe/piece-jointe.model';
import {AttributDossier} from '../attribut-dossier/attribut-dossier.model';
import {SocieteMandataire} from "../societeMandataire/societe-mandataire.model";

export enum EtatDossier {
    'EN_COURS',
    'TRANSMIS',
    'ENREGISTRE',
    'VALIDE',
    'REFUSE',
}

export const NatureDossier = {
    'INSCRIPTION': 'INSCRIPTION',
    'MODIFICATION': 'MODIFICATION',
    'REVALORISATION': 'REVALORISATION',
    'RENOUV_INSCRIPTION': 'RENOUV_INSCRIPTION',
    'RADIATION': 'RADIATION',
};

export enum DelaiReglementaire {
    'DANS_DELAIS',
    'HORS_DELAIS',
    'HORS_DELAI_REPORT'
}

export class Dossier {
    constructor(public id?: number,
                public commentaireAdministrateur?: string,
                public commentaireDemandeur?: string,
                public commentaireArchivage?: string,
                public dateArchivage?: any,
                public dateCreation?: any,
                public dateDepot?: any,
                public dateValidation?: any,
                public dateRefus?: any,
                public dateFinPriseEnCharge?: any,
                public dateDemandeInfoComplementaire?: any,
                public dateAccuseReception?: any,
                public dateModification?: any,
                public utilisateurModification?: string,
                public echantillon?: boolean,
                public elementsReference?: string,
                public handicap?: boolean,
                public infoComplementaireEnAttente?: boolean,
                public infoComplementaire?: string,
                public commentaireInfoComplementaire?: string,
                public nomProduit?: string,
                public nomSociete?: string,
                public numDossier?: string,
                public idDossierAssocie?: number,
                public numDossierAssocie?: string,
                public dossierCnedimts?: boolean,
                public referenceDossier?: string,
                public etat?: EtatDossier,
                public etatLibelle?: string,
                public nature?: string,
                public delaiReglementaire?: DelaiReglementaire,
                public natureLibelle?: string,
                public attributId?: number,
                public utilisateurModificationId?: number,
                public utilisateurCreation?: User,
                public pieceJointes?: PieceJointe[],
                public attributDossiers?: AttributDossier[],
                public dateDuplication?: any,
                public motivationRefus?: string,
                public societeMandataire?: SocieteMandataire,
				public natureChange?: boolean) {
        this.echantillon = false;
        this.handicap = false;
        this.infoComplementaireEnAttente = false;
        this.dossierCnedimts = false;
		this.natureChange = false;
    }
}
