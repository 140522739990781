export class DossierFiltre {
    constructor(
        public nature?: string,
        public etat?: string,
        public nomSociete?: string,
        public nomProduit?: string,
        public numDossier?: string,
        public archive?: boolean
    ) {
    }
}
