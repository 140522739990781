import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {DatePipe} from '@angular/common';

import {CookieService} from 'angular2-cookie/services/cookies.service';
import {
    AccountService,
    AuthServerProvider,
    CSRFService,
    DmLoginModalComponent,
    HasAnyAuthorityDirective,
    LoginModalService,
    LoginService,
    MedimedSharedCommonModule,
    MedimedSharedLibsModule,
    Principal,
    StateStorageService,
    UserService
} from './';
import {ListeReferenceService} from './liste-reference/liste-reference.service';
import {AccueilService} from './accueil/accueil.service';
import {DelegationService} from './delegation/delegation.service';
import {FileService} from './file/file.service';
import {BrowserUtil} from './browser/browser.util';
import {FiltreUtil} from './util/filtre.util';
import {FiltreTplComponent} from '../layouts/filtre-tpl/filtre-tpl.component';
import {ValidationComponent} from './validation/validation.component';
import {SpacesPipe} from './util/spaces.pipe';
import {PaginationPipe} from './util/pagination.pipe';
import {AlertErrorService} from './alert/alert-error.service';

@NgModule({
    imports: [
        MedimedSharedLibsModule,
        MedimedSharedCommonModule
    ],
    declarations: [
        DmLoginModalComponent,
        FiltreTplComponent,
        HasAnyAuthorityDirective,
        ValidationComponent,
        SpacesPipe,
        PaginationPipe
    ],
    providers: [
        CookieService,
        LoginService,
        LoginModalService,
        AccountService,
        StateStorageService,
        AlertErrorService,
        Principal,
        CSRFService,
        AuthServerProvider,
        UserService,
        AccueilService,
        DatePipe,
        ListeReferenceService,
        DelegationService,
        FileService,
        BrowserUtil,
        FiltreUtil
    ],
    entryComponents: [DmLoginModalComponent],
    exports: [
        MedimedSharedCommonModule,
        DmLoginModalComponent,
        HasAnyAuthorityDirective,
        DatePipe,
        FiltreTplComponent,
        ValidationComponent,
        SpacesPipe,
        PaginationPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class MedimedSharedModule {}
