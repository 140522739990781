import {Component, OnDestroy, OnInit} from '@angular/core';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs/Rx';

@Component({
    selector: 'dm-alert',
    template: `
        <div class="abso">
            <div class="alerts" role="alert">
                <div *ngFor="let alert of alerts" [ngClass]="{\'alert.position\': true, \'toast\': alert.toast}">
                    <ngb-alert [type]="alert.type" (close)="alert.close(alerts)">
                        <span [innerHTML]="alert.msg"></span>
                    </ngb-alert>
                </div>
            </div>
        </div>
    `
})
export class DmAlertComponent implements OnInit, OnDestroy {

    alerts: any[];
    cleanHttpNotificationListener: Subscription;

    constructor(private alertService: JhiAlertService, private eventManager: JhiEventManager) {
        this.cleanHttpNotificationListener = eventManager.subscribe('medimedApp.httpNotification', (message) => {
            this.addAlert(message.content);
        });
    }

    ngOnInit() {
        this.alerts = this.alertService.get();
    }

    ngOnDestroy() {
        this.alerts = [];
        if (this.cleanHttpNotificationListener !== undefined && this.cleanHttpNotificationListener !== null) {
            this.eventManager.destroy(this.cleanHttpNotificationListener);
        }
    }

    addAlert(message) {
        this.alerts.push(
            this.alertService.addAlert(
                {
                    type: 'info',
                    msg: message,
                    timeout: 5000,
                    toast: this.alertService.isToast(),
                    scoped: true
                },
                this.alerts
            )
        );
    }

}
