import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DocumentHorsDossierPipe} from './document-hors-dossier.pipe';
import {FileUploadModule} from 'ng2-file-upload';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {MedimedSharedModule} from '../../shared';
import {
    DocumentHorsDossierComponent,
    DocumentHorsDossierDeleteDialogComponent,
    DocumentHorsDossierDeletePopupComponent,
    DocumentHorsDossierDetailComponent,
    DocumentHorsDossierDialogComponent,
    DocumentHorsDossierPopupComponent,
    documentHorsDossierPopupRoute,
    DocumentHorsDossierPopupService,
    documentHorsDossierRoute,
    DocumentHorsDossierService
} from './';
import {ConfirmationService} from 'primeng/primeng';

const ENTITY_STATES = [
    ...documentHorsDossierRoute,
    ...documentHorsDossierPopupRoute,
];

@NgModule({
    imports: [
        MedimedSharedModule,
        RouterModule.forRoot(ENTITY_STATES, {useHash: true}),
        FileUploadModule,
        FormsModule,
        BrowserModule
    ],
    declarations: [

        DocumentHorsDossierComponent,
        DocumentHorsDossierDetailComponent,
        DocumentHorsDossierDialogComponent,
        DocumentHorsDossierDeleteDialogComponent,
        DocumentHorsDossierPopupComponent,
        DocumentHorsDossierDeletePopupComponent,
        DocumentHorsDossierPipe,

    ],
    entryComponents: [
        DocumentHorsDossierComponent,
        DocumentHorsDossierDialogComponent,
        DocumentHorsDossierPopupComponent,
        DocumentHorsDossierDeleteDialogComponent,
        DocumentHorsDossierDeletePopupComponent

    ],
    providers: [
        DocumentHorsDossierService,
        DocumentHorsDossierPopupService,
        ConfirmationService,
        {provide: LOCALE_ID, useValue: 'fr-FR'}
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})

export class MedimedDocumentHorsDossierModule {
}
