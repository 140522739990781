import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {Accueil} from './accueil.model';

@Injectable()
export class AccueilService {
    private resourceUrl = 'api/accueil';

    constructor(private http: HttpClient) { }

    getAccueilInfo(): Observable<Accueil> {
        return this.http.get<Accueil>(`${this.resourceUrl}/dashboard`);
    }

    getParams(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/params`);
    }

}
