import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';
import {Dossier} from './dossier.model';
import {DossierService} from './dossier.service';

@Injectable()
export class DossierPopupService {
    private isOpen = false;
    constructor(
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private router: Router,
        private dossierService: DossierService

    ) {}

    open(component: any, dossier?: Dossier | any): NgbModalRef {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        return this.dossierModalRef(component, dossier);
    }

    dossierModalRef(component: any, dossier: Dossier): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static'});
        modalRef.componentInstance.dossier = dossier;
        modalRef.result.then((result) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true});
            this.isOpen = false;
        }, (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true});
            this.isOpen = false;
        });

        return modalRef;
    }
}
