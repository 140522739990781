import {Component, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {Account, LoginModalService, Principal} from '../shared';
import {AccueilService} from '../shared/accueil/accueil.service';
import {Accueil} from '../shared/accueil/accueil.model';

@Component({
    selector: 'dm-home',
    templateUrl: './home.component.html',
    styleUrls: [
        'home.scss'
    ]

})
export class HomeComponent implements OnInit {
    account: Account;
    modalRef: NgbModalRef;
    accueilDto: Accueil;
    /** lien vers le guide pratique */
    guidePratique: string;
    /** Nombre de mois avant expiration du mot de passe */
    moisResetPassword: number;

    constructor(
        private principal: Principal,
        private loginModalService: LoginModalService,
        private eventManager: JhiEventManager,
        private accueilService: AccueilService
    ) {
    }

    ngOnInit() {
        this.accueilService.getParams().subscribe((params) => {
            this.guidePratique = params.guidePratique;
            this.moisResetPassword = params.moisResetPassword;
        });

        this.principal.identity().then((account) => {

            this.account = account;

            if (!account) {
                return;
            }

            this.loadAccueilInfos();

        });
        this.registerAuthenticationSuccess();

    }

    registerAuthenticationSuccess() {
        this.eventManager.subscribe('authenticationSuccess', (message) => {
            this.principal.identity().then((account) => {
                this.account = account;
            });

            // Charger les données du dashboard lors de l'auth
            this.loadAccueilInfos();

        });
    }

    loadAccueilInfos() {
        this.accueilService.getAccueilInfo().subscribe((accueil) => this.accueilDto = accueil);

    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    login() {
        this.modalRef = this.loginModalService.open();
    }
}
