import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MedimedSharedModule } from '../../shared';
import {
    AttributDossierService,
    AttributDossierPopupService,
    AttributDossierComponent,
    AttributDossierDetailComponent,
    AttributDossierDialogComponent,
    AttributDossierPopupComponent,
    AttributDossierDeletePopupComponent,
    AttributDossierDeleteDialogComponent,
    attributDossierRoute,
    attributDossierPopupRoute,
} from './';

const ENTITY_STATES = [
    ...attributDossierRoute,
    ...attributDossierPopupRoute,
];

@NgModule({
    imports: [
        MedimedSharedModule,
        RouterModule.forRoot(ENTITY_STATES, { useHash: true })
    ],
    declarations: [
        AttributDossierComponent,
        AttributDossierDetailComponent,
        AttributDossierDialogComponent,
        AttributDossierDeleteDialogComponent,
        AttributDossierPopupComponent,
        AttributDossierDeletePopupComponent,
    ],
    entryComponents: [
        AttributDossierComponent,
        AttributDossierDialogComponent,
        AttributDossierPopupComponent,
        AttributDossierDeleteDialogComponent,
        AttributDossierDeletePopupComponent,
    ],
    providers: [
        AttributDossierService,
        AttributDossierPopupService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedAttributDossierModule {}
