export class SocieteMandataire {
    public id?: any;
    public nomSociete?: string;
    public civilite?: string;
    public nom?: string;
    public prenom?: string;
    public telephone?: string;
    public adresseEmail?: string;
    public siret?: string;
    public adresseSociete?: string;

    constructor(
        id?: any,
        nomSociete?: string,
        civilite?: string,
        nom?: string,
        prenom?: string,
        telephone?: string,
        adresseEmail?: string,
        siret?: string,
        adresseSociete?: string
    ) {
        this.id = id ? id : null;
        this.nomSociete = nomSociete ? nomSociete : null;
        this.civilite = civilite ? civilite : null;
        this.nom = nom ? nom : null;
        this.prenom = prenom ? prenom : null;
        this.telephone = telephone ? telephone : null;
        this.adresseEmail = adresseEmail ? adresseEmail : null;
        this.siret = siret ? siret : null;
        this.adresseSociete = adresseSociete ? adresseSociete : null;
    }
}
