import {Routes} from '@angular/router';

import {UserRouteAccessService} from '../../shared';

import {UtilisateurDmComponent} from './utilisateur-dm.component';
import {UtilisateurDmDetailComponent} from './utilisateur-dm-detail.component';
import {UtilisateurDmPopupComponent} from './utilisateur-dm-dialog.component';
import {UtilisateurDmDeletePopupComponent} from './utilisateur-dm-delete-dialog.component';

export const utilisateurDmRoute: Routes = [
    {
        path: 'utilisateur-dm',
        component: UtilisateurDmComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'UtilisateurDms'
        },
        canActivate: [UserRouteAccessService]
    }, {
        path: 'utilisateur-dm/:id',
        component: UtilisateurDmDetailComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'UtilisateurDms'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const utilisateurDmPopupRoute: Routes = [
    {
        path: 'utilisateur-dm-new',
        component: UtilisateurDmPopupComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'UtilisateurDms'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'utilisateur-dm/:id/edit',
        component: UtilisateurDmPopupComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'UtilisateurDms'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'utilisateur-dm/:id/delete',
        component: UtilisateurDmDeletePopupComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'UtilisateurDms'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
