import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Response} from '@angular/http';

import {Observable} from 'rxjs/Rx';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {UtilisateurDm} from './utilisateur-dm.model';
import {UtilisateurDmPopupService} from './utilisateur-dm-popup.service';
import {UtilisateurDmService} from './utilisateur-dm.service';
import {ResponseWrapper, User, UserService} from '../../shared';

@Component({
    selector: 'dm-utilisateur-dm-dialog',
    templateUrl: './utilisateur-dm-dialog.component.html'
})
export class UtilisateurDmDialogComponent implements OnInit {

    utilisateurDm: UtilisateurDm;
    authorities: any[];
    isSaving: boolean;

    users: User[];

    utilisateurdms: UtilisateurDm[];

    constructor(
        public activeModal: NgbActiveModal,
        private alertService: JhiAlertService,
        private utilisateurDmService: UtilisateurDmService,
        private userService: UserService,
        private eventManager: JhiEventManager
    ) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.authorities = ['DEMANDEUR', 'CONSULTANT', 'ADMINISTRATEUR'];
        this.userService.query()
            .subscribe((res: ResponseWrapper) => { this.users = res.json; }, (res: ResponseWrapper) => this.onError(res.json));
        this.utilisateurDmService.query()
            .subscribe((res: ResponseWrapper) => { this.utilisateurdms = res.json; }, (res: ResponseWrapper) => this.onError(res.json));
    }
    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.utilisateurDm.id !== undefined) {
            this.subscribeToSaveResponse(
                this.utilisateurDmService.update(this.utilisateurDm));
        } else {
            this.subscribeToSaveResponse(
                this.utilisateurDmService.create(this.utilisateurDm));
        }
    }

    private subscribeToSaveResponse(result: Observable<UtilisateurDm>) {
        result.subscribe((res: UtilisateurDm) =>
            this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
    }

    private onSaveSuccess(result: UtilisateurDm) {
        this.eventManager.broadcast({ name: 'utilisateurDmListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError(error) {
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.isSaving = false;
        this.onError(error);
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }

    trackUserById(index: number, item: User) {
        return item.id;
    }

    trackUtilisateurDmById(index: number, item: UtilisateurDm) {
        return item.id;
    }

    getSelected(selectedVals: Array<any>, option: any) {
        if (selectedVals) {
            for (let i = 0; i < selectedVals.length; i++) {
                if (option.id === selectedVals[i].id) {
                    return selectedVals[i];
                }
            }
        }
        return option;
    }
}

@Component({
    selector: 'dm-utilisateur-dm-popup',
    template: ''
})
export class UtilisateurDmPopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private utilisateurDmPopupService: UtilisateurDmPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.modalRef = this.utilisateurDmPopupService
                    .open(UtilisateurDmDialogComponent, params['id']);
            } else {
                this.modalRef = this.utilisateurDmPopupService
                    .open(UtilisateurDmDialogComponent);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
