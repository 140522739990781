import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MedimedSharedModule} from '../shared';

import {
    accountState,
    Activate,
    ActivateComponent,
    DelegationAddDialogComponent,
    DelegationComponent,
    Password,
    PasswordComponent,
    PasswordResetFinish,
    PasswordResetFinishComponent,
    PasswordResetInit,
    PasswordResetInitComponent,
    PasswordStrengthBarComponent,
    Register,
    RegisterComponent,
    SettingsComponent
} from './';
import {
    DelegationDeleteDialogComponent,
    DelegationMgmtDeleteDialogComponent
} from './delegation/delegation-delete-dialog.component';
import {DelegationPopupService} from './delegation/delegation-popup.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        MedimedSharedModule,
        RouterModule.forRoot(accountState, { useHash: true })
    ],
    declarations: [
        ActivateComponent,
        RegisterComponent,
        PasswordComponent,
        PasswordStrengthBarComponent,
        PasswordResetInitComponent,
        PasswordResetFinishComponent,
        SettingsComponent,
        DelegationComponent,
        DelegationDeleteDialogComponent,
        DelegationAddDialogComponent,
        DelegationMgmtDeleteDialogComponent,
    ],
    providers: [
        Register,
        Activate,
        Password,
        PasswordResetInit,
        PasswordResetFinish,
        DelegationPopupService,
        NgbActiveModal
    ],
    entryComponents: [
        DelegationAddDialogComponent,
        DelegationDeleteDialogComponent,
        DelegationMgmtDeleteDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedAccountModule {}
