import './vendor.ts';

import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {NgxWebstorageModule} from 'ngx-webstorage';

import {DmAlertComponent, DmAlertErrorComponent, MedimedSharedModule, UserRouteAccessService} from './shared';
import {MedimedHomeModule} from './home/home.module';
import {MedimedAdminModule} from './admin/admin.module';
import {MedimedAccountModule} from './account/account.module';
import {MedimedEntityModule} from './entities/entity.module';
import {MedimedMentionslegalesModule} from './mentionslegales/mentionslegales.module';
// import {customHttpProvider} from './blocks/interceptor/http.provider';
import {PaginationConfig} from './blocks/config/uib-pagination.config';

import {AuthInterceptor} from './blocks/interceptor/auth.interceptor';
import {AuthExpiredInterceptor} from './blocks/interceptor/auth-expired.interceptor';
import {ErrorHandlerInterceptor} from './blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from './blocks/interceptor/notification.interceptor';

import {
    DmMainComponent,
    ErrorComponent,
    FooterComponent,
    LayoutRoutingModule,
    NavbarComponent,
    PageRibbonComponent,
    ProfileService
} from './layouts';
import {PRIMENG_CONFIG_CALENDAR_FR, PrimeNgConfigCalendarFr} from './config/app.primeng.config.calendar.fr';
import {ConfirmDialogModule} from 'primeng/primeng';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";

import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {MedimedSharedLibsModule} from "./shared/shared-libs.module";

registerLocaleData(localeFr);

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        LayoutRoutingModule,
        NgxWebstorageModule.forRoot({prefix: 'jhi', separator: '-'}),
        MedimedSharedModule,
        MedimedHomeModule,
        MedimedAdminModule,
        MedimedAccountModule,
        MedimedEntityModule,
        ConfirmDialogModule,
        MedimedMentionslegalesModule

    ],
    declarations: [
        DmMainComponent,
        NavbarComponent,
        ErrorComponent,
        PageRibbonComponent,
        FooterComponent

    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr-FR'},
        { provide: PRIMENG_CONFIG_CALENDAR_FR, useValue: PrimeNgConfigCalendarFr},
        ProfileService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthExpiredInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
        PaginationConfig,
        UserRouteAccessService,

    ],
    bootstrap: [DmMainComponent]
})
export class MedimedAppModule {}
