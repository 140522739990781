import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { JhiEventManager } from 'ng-jhipster';

import { UtilisateurDm } from './utilisateur-dm.model';
import { UtilisateurDmPopupService } from './utilisateur-dm-popup.service';
import { UtilisateurDmService } from './utilisateur-dm.service';

@Component({
    selector: 'dm-utilisateur-dm-delete-dialog',
    templateUrl: './utilisateur-dm-delete-dialog.component.html'
})
export class UtilisateurDmDeleteDialogComponent {

    utilisateurDm: UtilisateurDm;

    constructor(
        private utilisateurDmService: UtilisateurDmService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.utilisateurDmService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'utilisateurDmListModification',
                content: 'Deleted an utilisateurDm'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'dm-utilisateur-dm-delete-popup',
    template: ''
})
export class UtilisateurDmDeletePopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private utilisateurDmPopupService: UtilisateurDmPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.modalRef = this.utilisateurDmPopupService
                .open(UtilisateurDmDeleteDialogComponent, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
