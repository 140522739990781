import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';

import {User} from './user.model';
import {ResponseWrapper} from '../model/response-wrapper.model';
import {createRequestOption} from '../model/request-util';
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";

@Injectable()
export class UserService {
    private resourceUrl = 'api/users';

    constructor(private http: HttpClient) { }

    create(user: User): Observable<Response> {
        return this.http.post<Response>(this.resourceUrl, user);
    }

    update(user: User): Observable<Response> {
        return this.http.put<Response>(this.resourceUrl, user);
    }

    find(login: string): Observable<User> {
        return this.http.get<User>(`${this.resourceUrl}/${login}`);
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createRequestOption(req);
        return this.http.get(this.resourceUrl, {params})
            .map((res: HttpResponse<any>) => this.convertResponse(res));
    }

    delete(login: string): Observable<Response> {
        return this.http.delete<Response>(`${this.resourceUrl}/${login}`);
    }

    getAccueilInfo(): Observable<Response> {
        return this.http.get<Response>(`${this.resourceUrl}/check-password`);
    }

    authorities(): Observable<string[]> {
        return this.http.get<string[]>('api/users/authorities')/*.map((res: HttpResponse<any>) => {
            const json = JSON.stringify(res);
            return json;
        })*/;
    }

    private convertResponse(res: HttpResponse<any>): ResponseWrapper {
        return new ResponseWrapper(res.headers, res);
    }
}
