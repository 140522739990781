import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';
import {DossierPopupService} from './dossier-popup.service';
import {DossierService} from './dossier.service';

@Component({
    selector: 'dm-dossier-delete-dialog',
    templateUrl: './dossier-delete-dialog.component.html'
})
export class DossierDeleteDialogComponent {

    dossier: number;

    constructor(
        private dossierService: DossierService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete() {
        this.dossierService.delete(this.dossier).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'dossierListModification',
                content: 'Deleted an dossier'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'dm-dossier-delete-popup',
    template: ''
})
export class DossierDeletePopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private dossierPopupService: DossierPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.modalRef = this.dossierPopupService
                .open(DossierDeleteDialogComponent, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
