import {Component, OnInit} from '@angular/core';

import {AccountService, Principal, User} from '../../shared';
import {JhiEventManager} from 'ng-jhipster';
import {Account} from '../../shared/user/account.model';

@Component({
    selector: 'dm-settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
    error: string;
    success: string;
    settingsAccount: any;
    handicap: boolean;
    userDetails: User;
    profil: string;
    profilLabel: string;
    libStructure: string;

    constructor(private account: AccountService,
                private principal: Principal,
                private eventManager: JhiEventManager) {
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
            this.profil = account.authorities[0];
            this.settingsAccount = this.copyAccount(account);
            this.handicap = account.utilisateurDm.membreHandicap;
            this.userDetails = account;

            if (this.profil === Account.MEMBRE_COMITE_DM || this.profil === Account.ADMINISTRATEUR) {
                this.libStructure = 'organisme';
            } else if ((this.profil === Account.DEMANDEUR || this.profil === Account.CONSULTANT) && account.utilisateurDm.siret) {
                this.libStructure = 'société';
            } else {
                this.libStructure = 'syndicat';
            }

            this.profilLabel = Account.getLibelle(this.profil);

        });
    }

    private onSaveSuccess(result) {
        this.eventManager.broadcast({name: 'userListModification', content: 'OK'});
    }

    copyAccount(account) {
        return {
            activated: account.activated,
            email: account.email,
            firstName: account.firstName,
            langKey: account.langKey,
            lastName: account.lastName,
            login: account.login,
            imageUrl: account.imageUrl,
            civilite: account.utilisateurDm.civilite,
            adresseSociete: account.utilisateurDm.adresseSociete,
            nomSociete: account.utilisateurDm.nomSociete,
            telephone: account.utilisateurDm.telephoneFixe,
            telephonePortable: account.utilisateurDm.telephonePortable,
            siret: account.utilisateurDm.siret
        };
    }

}
