import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';

import {ProfileInfo} from './profile-info.model';

@Injectable()
export class ProfileService {

    private profileInfoUrl = 'api/profile-info';

    constructor(private http: HttpClient) { }

    getProfileInfo(): Observable<ProfileInfo> {
        return this.http.get<ProfileInfo>(this.profileInfoUrl);
    }
}
