import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';

import {Title} from '@angular/platform-browser';
import {StateStorageService} from '../../shared';
import {AlertErrorService} from '../../shared/alert/alert-error.service';

@Component({
    selector: 'dm-main',
    templateUrl: './main.component.html'
})
export class DmMainComponent implements OnInit {

    constructor(
        private titleService: Title,
        private router: Router,
        private alertErrorService: AlertErrorService,
        private $storageService: StateStorageService,
    ) {
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'medimedApp';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // suppression de l'affichage des alertError au changement de page
                this.alertErrorService.clear();
                this.titleService.setTitle(this.getPageTitle(this.router.routerState.snapshot.root));
            }
        });
    }
}
