import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {PieceJointe, TypePieceJointe} from '../piece-jointe/piece-jointe.model';
import {PieceJointeService} from '../piece-jointe/piece-jointe.service';
import {FileLikeObject, FileUploader} from 'ng2-file-upload';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Observable} from 'rxjs/Observable';
import {FileService} from '../../shared/file/file.service';
import {BrowserUtil} from '../../shared/browser/browser.util';
import {Fichier} from '../../shared/file/file.model';
import * as FileSaver from 'file-saver';
import {EtatDossier} from './dossier.model';
import {AccueilService} from '../../shared/accueil/accueil.service';
import {UtilisateurDmService} from "../utilisateur-dm";
import {User} from "../../shared";

@Component({
    selector: 'dm-piece-jointe-list',
    templateUrl: './piece-jointe-list.component.html',
    styleUrls: [
        'piece-jointe-list.scss'
    ]
})

export class PieceJointeListComponent implements OnInit, OnChanges {


    @Input() pieceJointes: PieceJointe[];
    @Input() dossierId: string;
    @Input() dossierNature: string;
    @Input() dossierEtat: EtatDossier;
    @Input() dossierUtilisateurCreation: User;
    @Input() profil: string;
    @Input() isSavingDossier: boolean;

    /** Signalement aux composants parents du nombre de pièces jointes en cours de sauvegarde */
    @Output() pjSavingChange:EventEmitter<number> = new EventEmitter<number>();

    ieBrowser: boolean;
    typePieceJointes: TypePieceJointe[];
    maxFileSize: number = 0;
    nbPjSaving: number = 0;

    constructor(private pieceJointeService: PieceJointeService,
                private localStorage: LocalStorageService,
                private sessionStorage: SessionStorageService,
                private alertService: JhiAlertService,
                private accueilService: AccueilService,
                private eventManager: JhiEventManager,
                private browserUtil: BrowserUtil,
                private fileService: FileService,
                private utilisateurService: UtilisateurDmService) {
    }

    ngOnInit(){
        this.accueilService.getParams().subscribe((params) => {
            this.maxFileSize = parseInt(params.maxFileSize, 10);
        });
    }

    ngOnChanges() {
        this.ieBrowser = this.browserUtil.isIE();

        this.getMaxFileSize().subscribe((maxFileSize) => {
            this.maxFileSize = maxFileSize;
            console.log(this.pieceJointes);
            // Pour chaque entrée de pieceJointes, on crée un fileUploader
            this.pieceJointes.forEach((pieceJointe) => {
                pieceJointe.uploader = this.newFileUploader(pieceJointe.id, pieceJointe.typeFormat);
            });
        });

        this.pieceJointeService.getTypePieceJointesByNature(this.dossierNature).subscribe((types) => {
            // filtre sur les pièces jointes qui concernent le profil utilisateur qui a créé le dossier
            this.typePieceJointes = types.filter(type => type.authorities.indexOf(this.dossierUtilisateurCreation.utilisateurDm.fonction) > -1);
        });

    }

    private getMaxFileSize() : Observable<number>{
        if(!this.maxFileSize){
            return this.accueilService.getParams().map((params) => parseInt(params.maxFileSize, 10));
        }else{
            return Observable.of(this.maxFileSize);
        }
    }

    /**
     * Evénement lancé sur le Over d'une dropZone
     * @param e
     * @param pj
     */
    fileOverDropZone(e: any, pj: PieceJointe): void {
        pj.hasDropZoneOver = e;
    }

    /**
     * Ajoute une nouvelle pièce jointe
     */
    addPieceJointe(typePj: TypePieceJointe) {
        this.nbPjSaving++;
        this.pjSavingChange.emit(this.nbPjSaving);
        const pj = new PieceJointe();
        pj.dossierId = parseInt(this.dossierId, 10);
        pj.type = typePj;
        this.subscribeToSaveResponse(this.pieceJointeService.create(pj));
    }

    private subscribeToSaveResponse(result: Observable<PieceJointe>) {
        result.subscribe((res: PieceJointe) =>
            this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
    }

    private onSaveSuccess(result: PieceJointe) {
        this.nbPjSaving--;
        this.pjSavingChange.emit(this.nbPjSaving);
        this.eventManager.broadcast({name: 'pieceJointeListModification', content: 'OK'});
    }

    private onSaveError(error) {
        this.nbPjSaving--;
        this.pjSavingChange.emit(this.nbPjSaving);
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.onError(error);
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }

    /**
     * Crée un fileUploader avec les paramètres par défaut :
     * - queueLimit : limitiation du nombre de fichiers à envoyer par dropZone
     * - autoUpload : envoi du fichier instantanément
     * - url : url sur laquelle envoyer le fichier (contient l'id de la pj)
     * - header : Ajoute les header pour prendre en compte le token d'identification
     * @param id de la pièce jointe
     * @param format
     * @return {FileUploader}
     */
    private newFileUploader(id: number, format: string): FileUploader {
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

        let allowedFileTypes = null;

        // Si un format de fichier est requis
        if (format === 'WORD') {
            allowedFileTypes = PieceJointe.EXTENSION_WORD;
        } else if (format === 'PDF') {
            allowedFileTypes = PieceJointe.EXTENSION_PDF;
        }

        const fileUploadParams = {
            queueLimit: 1,
            autoUpload: true,
            maxFileSize: 1024 * 1024 * this.maxFileSize ,
            url: PieceJointeService.resourceFileUrl + id,
            headers: [{name: 'Authorization', value: 'Bearer ' + token}],
            allowedFileType: allowedFileTypes
        };



        const uploader: FileUploader = new FileUploader(fileUploadParams);

        uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options, format);

        uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            // actualisation de la liste des PJ
            if (status !== 200) {
                this.nbPjSaving--;
                this.pjSavingChange.emit(this.nbPjSaving);
                const reponse = JSON.parse(response);
                this.alertService.error(reponse.message);
                uploader.removeFromQueue(item);
                return;
            }

            this.onSaveSuccess(null);
        };

        uploader.onBeforeUploadItem = () => {
            this.nbPjSaving++;
            this.pjSavingChange.emit(this.nbPjSaving);
        }

        return uploader;
    }

    /**
     * Lors de l'ajout d'un fichier dans la dropZone,
     * Affiche les erreurs de validation sur le fichier déposé
     * @param item
     * @param filter
     * @param options
     */
    onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any, formatAttendu: string) {
        let msgErreur = null;

        // Création du msg d erreur en fonction du type d'erreur détectée par l'uploader
        switch (filter.name) {
            case 'fileSize':
                const sizeMb = (Math.floor(item.size / 10000) / 100);
                const maxSizeMb = this.maxFileSize;
                msgErreur = `Le fichier est trop volumineux (${sizeMb} Mo), ${maxSizeMb} Mo max autorisés`;
                break;
            case 'fileType':
                msgErreur = `Le type du fichier ${item.name} n'est pas valide (format attendu : ${formatAttendu})`;
                break;
            default:
                msgErreur = (`Une erreur est survenue, (type d'erreur = ${filter.name})`);
        }
        // affichage de l'erreur
        this.alertService.error(msgErreur);
    }

    /**
     * Télécharge le fichier lié à la pièce jointe
     * @param pj
     */
    download(pj: PieceJointe) {
        this.fileService.downloadPieceJointe(pj.id).subscribe((fichier: Fichier) => {
            FileSaver.saveAs(fichier.blob, fichier.name);
        });
    }

    /**
     * Télécharge les fichiers des pièces jointes d'un même dossier
     * @param pj
     */
    downloadAll(dossier: number) {
        this.fileService.downloadPiecesJointes(dossier).subscribe((fichier: Fichier) => {
            FileSaver.saveAs(fichier.blob, fichier.name);
        }, error => this.alertService.error("Une erreur est survenue lors de la génération par le serveur du zip des pièces jointes."));
    }

}
