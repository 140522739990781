import {Route} from '@angular/router';

import { MentionslegalesComponent } from './mentionslegales.component';

export const mentionslegalesRoute: Route = {
    path: 'mentionslegales',
    component: MentionslegalesComponent,
    data: {
        authorities: [],
        pageTitle: 'Mentions légales'
    }
};
