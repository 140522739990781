import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Dossier} from '../dossier.model';
import {Account} from '../../../shared/user/account.model';

@Component({
    selector: 'dm-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['timeline.scss']
})
export class TimelineComponent implements OnInit, OnChanges {

    @Input() dossier: Dossier;
    @Input() profil: string;
    nomModif: string;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        // 0041552 - Pour le profil demandeur uniquement, lorsqu’une action est réalisée par un administrateur
        // Souhait de voir figurer « le CEPS » au lieu du nom de l’administrateur
        const CEPS_USER = '(CEPS)';
        const isCEPS = this.dossier.utilisateurModification && this.dossier.utilisateurModification.indexOf(CEPS_USER) !== -1;
        this.nomModif = (isCEPS && this.profil !== Account.ADMINISTRATEUR) ? 'le CEPS' : this.dossier.utilisateurModification;
    }

}
