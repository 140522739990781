import {Component} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';
import {DelegationService} from '../../shared/delegation/delegation.service';
import {User} from '../../shared/user/user.model';

@Component({
    selector: 'dm-delegation-add-dialog',
    templateUrl: './delegation-add-dialog.component.html'
})
export class DelegationAddDialogComponent {
    user: User;
    constructor(
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager,
        private delegationService: DelegationService
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmAdd(id: number) {
        this.delegationService.ajouter(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'delegationListModification',
                content: `Ajout d'un délégué`
            });
            this.activeModal.dismiss(true);
    });
    }
}
