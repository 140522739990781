import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Response} from '@angular/http';
import {BrowserUtil} from '../../shared/browser/browser.util';

import {Observable} from 'rxjs/Rx';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {FileUploader} from 'ng2-file-upload';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {FileService} from '../../shared/file/file.service';
import {Fichier} from '../../shared/file/file.model';

import {DocumentHorsDossier} from './document-hors-dossier.model';
import {DocumentHorsDossierPopupService} from './document-hors-dossier-popup.service';
import {DocumentHorsDossierService} from './document-hors-dossier.service';
import {DocumentHorsDossierCommentaires} from './document-hors-dossier.commentaires.model';
import {DocumentHorsDossierFilter} from './document-hors-dossier.filter.model';
import {DocumentHorsDossierRepertoire} from './document-hors-dossier.repertoire.model';
import {Account} from '../../shared/user/account.model';
import fileSaver = require('file-saver');

@Component({
    selector: 'dm-document-hors-dossier-dialog',
    templateUrl: './document-hors-dossier-dialog.component.html'
})
export class DocumentHorsDossierDialogComponent implements OnInit {
    ieBrowser: boolean;
    documentHorsDossier: DocumentHorsDossier;
    documentHorsDossiers: DocumentHorsDossier[];
    authorities: any[];
    isSaving = true;
    prodMode = true;
    repertoire: DocumentHorsDossierRepertoire;
    filter: DocumentHorsDossierFilter;
    commentaires: DocumentHorsDossierCommentaires;
    repertoires: any[];
    allowedExtensions: String[];
    search = (text$: Observable<string>) =>
        text$
            .debounceTime(200)
            .distinctUntilChanged()
            .map((term) => term.length < 2 ? []
                : this.repertoires.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

    constructor(public activeModal: NgbActiveModal,
                private alertService: JhiAlertService,
                public documentHorsDossierService: DocumentHorsDossierService,
                private eventManager: JhiEventManager,
                private localStorage: LocalStorageService,
                private sessionStorage: SessionStorageService,
                private fileService: FileService,
                private browserUtil: BrowserUtil) {
        this.documentHorsDossiers = [];
    }

    ngOnInit() {
        this.ieBrowser = this.browserUtil.isIE();

        if (!this.documentHorsDossierService.downloadHide || this.ieBrowser) {
            this.isSaving = false;
        }
        this.documentHorsDossier.uploader = this.newFileUploader();
        this.authorities = [Account.DEMANDEUR, Account.CONSULTANT, Account.MEMBRE_COMITE_DM, Account.ADMINISTRATEUR];
        this.documentHorsDossierService.query().subscribe((res) => {
            this.documentHorsDossiers = res.json;
            this.repertoiresFill(this.documentHorsDossiers);

        });
        this.documentHorsDossierService.getAllowedExtensions().subscribe((map) => {
            this.allowedExtensions = map.extensions;
        });
    }

    repertoireFiltre(repertoires: any) { // double boucle for qui parcourt les repertoires, les compare un à un et supprime les doublons
        const newDocumentHorsDossiers = [];
        let current: any;
        this.repertoires.forEach((x) => newDocumentHorsDossiers.push(x));
        for (let i = newDocumentHorsDossiers.length; i > 0; i--) {
            current = newDocumentHorsDossiers[i];
            for (let n = newDocumentHorsDossiers.length - 1; n > 0; n--) {
                if ((current === newDocumentHorsDossiers[n - 1]) && (i !== (n - 1))) {
                    newDocumentHorsDossiers.splice(n, 1);
                }
            }
        }
        this.repertoires = newDocumentHorsDossiers;
        return newDocumentHorsDossiers;
    }

    repertoiresFill(document: DocumentHorsDossier[]): any {
        this.repertoires = [];
        for (let i = 0; i < document.length; i++) {
            this.repertoires.push(document[i].repertoire);
        }
        this.repertoireFiltre(this.repertoires);
    }

    loadAll(document: any[]): any {
        this.documentHorsDossierService.query().subscribe((res) => document.push(res));
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    fileOverDropZone(e: boolean, dh: DocumentHorsDossier): any {
        this.isSaving = false;
        dh.hasDropZoneOver = e;
        if (this.documentHorsDossier.uploader.queue.length === 0) {
            this.isSaving = true;
        }
    }

    save() {
        this.isSaving = true;
        if (this.documentHorsDossier.id !== undefined) {
            this.subscribeToSaveResponseEdit(
                this.documentHorsDossierService.update(this.documentHorsDossier));
        } else {
            this.subscribeToSaveResponse(
                this.documentHorsDossierService.create(this.documentHorsDossier));
        }
    }

    private subscribeToSaveResponseEdit(result: Observable<DocumentHorsDossier>) {
        result.subscribe(res => {
            if (this.documentHorsDossier.uploader.queue.length !== 0) {
                this.subscribeToSaveResponse(this.documentHorsDossierService.update(this.documentHorsDossier));
            }
            this.onSaveSuccess(res), (res: Response) => this.onSaveError(res);
        });
    }

    private subscribeToSaveResponse(result: Observable<DocumentHorsDossier>) {
        result.subscribe(res  => {
            this.paramUploader(res), (res: Response) => this.onSaveError(res);
        });

    }

    private paramUploader(res) {
        this.documentHorsDossier.uploader.queue.forEach((item) => item.url = DocumentHorsDossierService.resourceFileUrl + res.id);
        if (this.documentHorsDossier.uploader.queue.length === 1) {
            this.documentHorsDossier.uploader.uploadAll();
        } else {
            this.isSaving = true;
        }
        this.documentHorsDossier.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            if (status !== 200) {
                const reponse = JSON.parse(response);
                this.alertService.error(reponse.message);
                this.isSaving = false;
                this.activeModal.close();
                return;
            }
            else if(status === 200) {
                   this.alertService.info(`Le document a été créé.`);
            }
            this.onSaveSuccess(res);
        };
    }

    private onSaveSuccess(result: DocumentHorsDossier) {
        this.isSaving = false;
        this.eventManager.broadcast({name: 'documentHorsDossierListModification', content: 'OK'});
        const x = setTimeout(() => this.activeModal.dismiss(result), 130);
    }

    private onSaveError(error) {
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.isSaving = false;
        this.onError(error);
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }

    private newFileUploader(): FileUploader {
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        const uploader: FileUploader = new FileUploader(
            {
                url: DocumentHorsDossierService.resourceFileUrl,
                queueLimit: 1,
                autoUpload: false,
                headers: [{name: 'Authorization', value: 'Bearer ' + token}]
            });
        uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

        };
        uploader.onAfterAddingFile = (item: any) => {
            let checkExtensions: boolean;
            for (let i = 0; i < this.allowedExtensions.length; i++) {
                if (item.file.name.substr(item.file.name.length - ((this.allowedExtensions[i].length) + 1), (this.allowedExtensions[i].length) + 1).toLowerCase() === ('.' + this.allowedExtensions[i].toLowerCase())) {
                    checkExtensions = true;
                    this.documentHorsDossier.nomFichier = item.file.name;
                    break;
                }
            }
            if (!checkExtensions) {
                this.documentHorsDossier.uploader.clearQueue();
            }
        };
        return uploader;
    }

    download(id: number) {
        this.fileService.downloadDocumentHorsDossier(id).subscribe((fichier: Fichier) => {
            fileSaver.saveAs(fichier.blob, fichier.name);
        });
    }
}

@Component({
    selector: 'dm-document-hors-dossier-popup',
    template: ''
})
export class DocumentHorsDossierPopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(private route: ActivatedRoute,
                private documentHorsDossierPopupService: DocumentHorsDossierPopupService,
                private localStorage: LocalStorageService,
                private sessionStorage: SessionStorageService,
                private alertService: JhiAlertService,
                private eventManager: JhiEventManager) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if (params['id']) {
                this.modalRef = this.documentHorsDossierPopupService
                    .open(DocumentHorsDossierDialogComponent, params['id']);
            } else {
                this.modalRef = this.documentHorsDossierPopupService
                    .open(DocumentHorsDossierDialogComponent);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
