import { Pipe, PipeTransform } from '@angular/core';
import {User} from '../../shared/user/user.model';

@Pipe({
    name: 'userFilter',
    pure: false
})
export class UserFilterPipe implements PipeTransform {
    transform(users: User[], userFilter: User): any {
        if (!users || !userFilter) {
            return users;
        }

        // filter items array, items which match and return true will be kept, false will be filtered out
        return users.filter((user) =>
            filtrerText(userFilter.email, user.email) &&
            filtrerText(userFilter.lastName, user.lastName)   &&
            filtrerText(userFilter.firstName, user.firstName)   &&
            ((userFilter.activated === null || userFilter.activated === false) || (userFilter.activated === !user.activated && user.registered))
        );

        function filtrerText(champFiltre: string, champ: string): boolean {
            return (champFiltre === '' || champFiltre === null) ||
                (champ !== null && champ.toUpperCase().indexOf(champFiltre.toUpperCase()) !== -1);
        }
    }
}
