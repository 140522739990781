import {Routes} from '@angular/router';

import {UserRouteAccessService} from '../../shared';

import {AttributDossierComponent} from './attribut-dossier.component';
import {AttributDossierDetailComponent} from './attribut-dossier-detail.component';
import {AttributDossierPopupComponent} from './attribut-dossier-dialog.component';
import {AttributDossierDeletePopupComponent} from './attribut-dossier-delete-dialog.component';

export const attributDossierRoute: Routes = [
    {
        path: 'attribut-dossier',
        component: AttributDossierComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'AttributDossiers'
        },
        canActivate: [UserRouteAccessService]
    }, {
        path: 'attribut-dossier/:id',
        component: AttributDossierDetailComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'AttributDossiers'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const attributDossierPopupRoute: Routes = [
    {
        path: 'attribut-dossier-new',
        component: AttributDossierPopupComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'AttributDossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'attribut-dossier/:id/edit',
        component: AttributDossierPopupComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'AttributDossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'attribut-dossier/:id/delete',
        component: AttributDossierDeletePopupComponent,
        data: {
            authorities: ['DEMANDEUR', 'CONSULTANT'],
            pageTitle: 'AttributDossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
