import {Component, OnDestroy} from '@angular/core';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs/Rx';
import {AlertErrorService} from './alert-error.service';

@Component({
    selector: 'dm-alert-error',
    template: `
        <div class="alerts" role="alert">
            <div *ngFor="let alert of alerts" [ngClass]="{\'alert.position\': true, \'toast\': alert.toast}">
                <ngb-alert type="{{alert.type}}" (close)="clear()">
                    <pre [innerHTML]="alert.msg"></pre>
                </ngb-alert>
            </div>
        </div>`
})
export class DmAlertErrorComponent implements OnDestroy {

    alerts: any[];
    cleanHttpErrorListener: Subscription;

    constructor(private alertService: JhiAlertService,
                private eventManager: JhiEventManager,
                private alertErrorService: AlertErrorService) {

        this.alerts = this.alertErrorService.errors;

        this.cleanHttpErrorListener = eventManager.subscribe('medimedApp.httpError', (response) => {
            let i;
            const httpResponse = response.content;
            switch (httpResponse.status) {
                // connection refused, server not reachable
                case 0:
                    this.addErrorAlert('Erreur lors de la tentative de connexion au serveur', 'error.server.not.reachable');
                    break;

                case 400:
                    const arr = Array.from(httpResponse.headers);
                    const headers = [];
                    for (i = 0; i < arr.length; i++) {
                        if (arr[i][0].endsWith('app-error') || arr[i][0].endsWith('app-params')) {
                            headers.push(arr[i][0]);
                        }
                    }
                    headers.sort();
                    let errorHeader = null;
                    let entityKey = null;
                    if (headers.length > 1) {
                        errorHeader = httpResponse.headers.get(headers[0]);
                        entityKey = httpResponse.headers.get(headers[1]);
                    }
                    if (errorHeader) {
                        const entityName = entityKey;
                        this.addErrorAlert(errorHeader, errorHeader, {entityName});
                    } else if (httpResponse.statusText !== '' && httpResponse.error && httpResponse.error.message) {
                        window.scrollTo(0, 0);
                        this.addErrorAlert(httpResponse.error.message, httpResponse.error.message, null, 30000);
                    } else {
                        this.addErrorAlert('Une erreur est survenue.');
                    }
                    break;

                case 404:
                    this.addErrorAlert('Introuvable', 'error.url.not.found');
                    break;
                default:
                    if (httpResponse.statusText !== '' && httpResponse.message) {
                        if (httpResponse.error) {
                            if (httpResponse.error.message) {
                                if (httpResponse.error.message.endsWith('internalServerError')) {
                                    this.addErrorAlert('Une erreur est survenue.');
                                } else {
                                    this.addErrorAlert(httpResponse.error.message);
                                }
                            } else {
                                const errorName = Object.getOwnPropertyNames(httpResponse.error);
                                if (errorName.length >= 0) {
                                     if(errorName[0] !== 'AuthenticationException') {
                                        const errorText = httpResponse.error[errorName[0]];
                                        this.addErrorAlert(errorText);
                                    }
                                }
                            }
                        } else {
                            this.addErrorAlert(httpResponse.message);
                        }
                    }
            }
        });
    }

    ngOnDestroy() {
        if (this.cleanHttpErrorListener !== undefined && this.cleanHttpErrorListener !== null) {
            this.eventManager.destroy(this.cleanHttpErrorListener);
            this.alerts = [];
        }
    }

    addErrorAlert(message, key?, data?, duree = 12000) {
        // fix : les alertes erreur ne sont pas empilables (1 seule affichée à la fois)
        this.alertErrorService.clear();
        this.alerts.push(
            this.alertService.addAlert(
                {
                    type: 'danger',
                    msg: message,
                    timeout: duree,
                    toast: this.alertService.isToast(),
                    scoped: true
                },
                this.alerts
            )
        );
    }

    clear() {
        this.alertErrorService.clear();
    }

}
