export * from './dossier.model';
export * from './dossier-popup.service';
export * from './dossier.service';
export * from './dossier-archivage-dialog.component';
export * from './dossier-delete-dialog.component';
export * from './dossier-detail.component';
export * from './dossier.component';
export * from './dossier-new.component';
export * from './dossier.route';
export * from './dossier.pipe';
