import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { JhiEventManager  } from 'ng-jhipster';

import { Dossier } from './dossier.model';
import { DossierService } from './dossier.service';
import {PieceJointe} from '../piece-jointe/piece-jointe.model';
import {PieceJointeService} from '../piece-jointe/piece-jointe.service';
import {ResponseWrapper} from '../../shared/model/response-wrapper.model';
import {Observable} from 'rxjs/Observable';

@Component({
    selector: 'dm-dossier-detail',
    templateUrl: './dossier-detail.component.html'
})
export class DossierDetailComponent implements OnInit, OnDestroy {

    dossier: Dossier;
    pieceJointes: Observable<PieceJointe[]>;
    private subscription: Subscription;
    private eventSubscriber: Subscription;
    private eventSubscriberPieceJointes: Subscription;

    constructor(
        private eventManager: JhiEventManager,
        private dossierService: DossierService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInDossiers();
        this.registerChangeInPieceJointes();
    }

    load(id) {
        this.dossierService.find(id).subscribe((dossier) => {
            this.dossier = dossier;
            this.loadPieceJointes();
        });
    }

    loadPieceJointes() {
        this.dossierService.getPieceJointes(this.dossier.id).subscribe(
            (res: ResponseWrapper) => {
                this.pieceJointes = Observable.of(res.json);
            });
    }

    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
        this.eventManager.destroy(this.eventSubscriberPieceJointes);
    }

    registerChangeInDossiers() {
        this.eventSubscriber = this.eventManager.subscribe(
            'dossierListModification',
            (response) => this.load(this.dossier.id)
        );
    }

    registerChangeInPieceJointes() {
        this.eventSubscriberPieceJointes = this.eventManager.subscribe('pieceJointeListModification', (response) => this.loadPieceJointes());
    }
}
