import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

import { UtilisateurDm } from './utilisateur-dm.model';
import { ResponseWrapper, createRequestOption } from '../../shared';

@Injectable()
export class UtilisateurDmService {

    private resourceUrl = 'api/utilisateur-dms';

    constructor(private http: HttpClient) { }

    create(utilisateurDm: UtilisateurDm): Observable<UtilisateurDm> {
        const copy = this.convert(utilisateurDm);
        return this.http.post(this.resourceUrl, copy);
    }

    update(utilisateurDm: UtilisateurDm): Observable<UtilisateurDm> {
        const copy = this.convert(utilisateurDm);
        return this.http.put(this.resourceUrl, copy);
    }

    find(id: number): Observable<UtilisateurDm> {
        return this.http.get(`${this.resourceUrl}/${id}`);
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createRequestOption(req);
        return this.http.get(this.resourceUrl, {params})
            .map((res: HttpResponse<any>) => this.convertResponse(res));
    }

    delete(id: number): Observable<Response> {
        return this.http.delete<Response>(`${this.resourceUrl}/${id}`);
    }

    private convertResponse(res: HttpResponse<any>): ResponseWrapper {
        return new ResponseWrapper(res.headers, res);
    }

    private convert(utilisateurDm: UtilisateurDm): UtilisateurDm {
        const copy: UtilisateurDm = Object.assign({}, utilisateurDm);
        return copy;
    }
}
