import {Component, ElementRef, OnDestroy, OnInit, Renderer} from '@angular/core';
import {Subscription} from 'rxjs/Rx';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {Dossier} from './dossier.model';
import {DossierService} from './dossier.service';
import {ITEMS_PER_PAGE, Principal} from '../../shared';
import {ListeRefElement} from '../../shared/liste-reference/liste-ref-element.model';
import {ListeReferenceService} from '../../shared/liste-reference/liste-reference.service';
import {DossierFiltre} from './dossier-filtre.model';

@Component({
    selector: 'dm-dossier',
    templateUrl: './dossier.component.html'
})
export class DossierComponent implements OnInit, OnDestroy {

    dossiers: Dossier[];
    currentAccount: any;
    profil: string;
    eventSubscriber: Subscription;
    itemsPerPage: number;
    links: any;

    page: any;
    previousPage: any;

    predicate: any;
    queryCount: any;
    reverse: any;
    totalItems: number;

    /** ne pas afficher les dossier archivés */
    showArchive = false;

    /** Filtre */
    dossierFiltre = new DossierFiltre();

    /** Liste de référence */
    natures: any[];
    etats: ListeRefElement[];

    constructor(private dossierService: DossierService,
                private alertService: JhiAlertService,
                private eventManager: JhiEventManager,
                private principal: Principal,
                private elRef: ElementRef,
                private renderer: Renderer,
                private listeReferenceService: ListeReferenceService) {
        this.dossiers = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 0;
        this.links = {
            last: 0
        };
        this.predicate = 'id';
        this.reverse = true;
    }

    ngOnInit() {
        this.loadAll();
        this.principal.identity().then((account) => {
            this.currentAccount = account;
            this.profil = account.authorities[0];

            this.listeReferenceService.get(this.listeReferenceService.ETAT_DOSSIER).subscribe((etats) => {
                this.etats = this.listeReferenceService.toSelectFilterList(etats);
            });

        });
        this.registerChangeInDossiers();

        // Récupération des listes de ref
        // natures
        this.listeReferenceService.get(this.listeReferenceService.NATURE_DOSSIER).subscribe((natures) => {
            this.natures = this.listeReferenceService.toSelectFilterList(natures);
        });
        // etats

    }

    loadAll() {

        this.dossierService.findAll().subscribe((dossiers) => {
            this.dossiers = dossiers;
            // fix : initialise le filtre en affichant uniquement les dossiers non archivés
            const cbArchivageElement = this.elRef.nativeElement.querySelector('#cbArchivage');
            if (cbArchivageElement) {
                 this.renderer.invokeElementMethod(cbArchivageElement, 'click', []);
                 this.renderer.invokeElementMethod(cbArchivageElement, 'click', []);
            }

        });
    }

    reset() {
        this.page = 0;
        this.dossiers = [];
        this.loadAll();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInDossiers() {
        this.eventSubscriber = this.eventManager.subscribe('dossierListModification', (response) => {
            this.reset();
        });
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }
}
