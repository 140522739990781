
const enum TypeAttribut {
    'A_DEFINIR'

};
export class AttributDossier {
    constructor(
        public id?: number,
        public valeur?: string,
        public type?: TypeAttribut,
        public typeLibelle?: string,
        public typeTaille?: number,
        public obligatoire?: TypeAttribut,
        public dossierId?: number,
    ) {
    }
}
