import {Injectable} from '@angular/core';

@Injectable()
export class BrowserUtil {

    constructor() { }

    isIE(): boolean {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ') > 0;
        const trident = ua.indexOf('Trident/') > 0;
        const edge = ua.indexOf('Edge/') > 0;

        return msie || trident || edge;
    }

}
