import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../shared';
import {SettingsComponent} from './settings.component';
import {Account} from '../../shared/user/account.model';
export const settingsRoute: Route = {
    path: 'settings',
    component: SettingsComponent,
    data: {
        authorities: Account.CONNECTE,
        pageTitle: 'Paramètres du compte'
    },
    canActivate: [UserRouteAccessService]
};
