import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {UserModalService} from './user-modal.service';
import {User, UserService} from '../../shared';
import {UtilisateurDm} from '../../entities/utilisateur-dm/utilisateur-dm.model';
import {Account} from '../../shared/user/account.model';

@Component({
    selector: 'dm-user-mgmt-dialog',
    templateUrl: './user-management-dialog.component.html'
})
export class UserMgmtDialogComponent implements OnInit {

    user: User;
    authorities: any[];
    isSaving: Boolean;
    typeStructure: any;

    constructor(public activeModal: NgbActiveModal,
                private userService: UserService,
                private eventManager: JhiEventManager) {
    }

    ngOnInit() {
        console.info('user', this.user);
        console.info('userAuth', this.user.authorities);
        const profil = this.user.authorities[0];
        if (this.user.utilisateurDm.siret) {
            this.typeStructure = 'societe';
        } else {
            this.typeStructure = 'syndicat';
        }

        this.isSaving = false;

        if (profil === Account.DEMANDEUR) {
            this.authorities = [Account.DEMANDEUR,Account.CONSULTANT];
        } else if (profil === Account.CONSULTANT) {
            this.authorities = [Account.CONSULTANT];
        } else {
            this.authorities = [Account.MEMBRE_COMITE_DM, Account.ADMINISTRATEUR];
        }

        // ajout du UtilisateurDm si le User courant est un User sans utilisateurDm
        if (this.user.utilisateurDm == null) {
            this.user.utilisateurDm = new UtilisateurDm();
        }
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    onStructureChange(typeStructure: string) {
        if (typeStructure === 'syndicat') {
            this.user.utilisateurDm.siret = null;
        }
    }

    save() {
        this.isSaving = true;
        if (this.user.id !== null) {
            this.user.authorities = this.user.authorities instanceof Array ? this.user.authorities : [this.user.authorities];
            this.userService.update(this.user).subscribe((response) => this.onSaveSuccess(response), () => this.onSaveError());
        } else {
            this.user.langKey = 'fr';
            this.userService.create(this.user).subscribe((response) => this.onSaveSuccess(response), () => this.onSaveError());
        }
    }

    private onSaveSuccess(result) {
        this.eventManager.broadcast({name: 'userListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

}

@Component({
    selector: 'dm-user-dialog',
    template: ''
})
export class UserDialogComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(private route: ActivatedRoute,
                private userModalService: UserModalService) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if (params['login']) {
                this.modalRef = this.userModalService.open(UserMgmtDialogComponent, params['login']);
            } else {
                this.modalRef = this.userModalService.open(UserMgmtDialogComponent);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
