import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {ListeRefElement} from './liste-ref-element.model';

@Injectable()
export class ListeReferenceService  {

    NATURE_DOSSIER = 'nature-dossier';
    ETAT_DOSSIER = 'etat-dossier';
    TYPE_COMPTE = 'type-compte';
    DELAI_REGLEMENTAIRE = 'delai-reglementaire';

    constructor(private http: HttpClient) { }

    get(listeRef: string): Observable<ListeRefElement[]> {
        return this.http.get<ListeRefElement[]>(`api/liste-reference/${listeRef}`);
    }

    /**
     * Duplique et ajoute une value (null) à la liste
     * (utilisée dans les select avec choix de valeur vide)
     * @param listeRefs
     * @returns {ListeRefElement[]}
     */
    toSelectFilterList(listeRefs: ListeRefElement[]) {
        const newListe = new Array<ListeRefElement>();
        newListe.push(new ListeRefElement(null, '-'));
        for (const element of listeRefs) {
            newListe.push(new ListeRefElement(element.label, element.label));
        }
        return newListe;
    }

    /**
     * Duplique et ajoute une value (null) à la liste
     * (utilisée dans les select avec choix de valeur vide)
     * @param listeRefs
     * @returns {ListeRefElement[]}
     */
    addBlankVal(listeRefs: ListeRefElement[]) {
        const newListe = new Array<ListeRefElement>();
        newListe.push(new ListeRefElement(null, ''));
        for (const element of listeRefs) {
            newListe.push(new ListeRefElement(element.value, element.label));
        }
        return newListe;
    }

}
