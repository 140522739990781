import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {User} from '../user/user.model';

@Injectable()
export class DelegationService {
    private resourceUrl = 'api/delegation';

    constructor(private http: HttpClient) { }

    findDelegues(): Observable<User[]> {
        return this.http.get<User[]>(`${this.resourceUrl}/delegues`);
    }

    getByEmail(email: string): Observable<User> {
        return this.http.get(`${this.resourceUrl}/email/${email}`);
    }

    ajouter(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/ajouter/${id}`);
    }

    supprimer(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/supprimer/${id}`);
    }

}
