import {DelegationComponent} from './delegation.component';
import {UserRouteAccessService} from '../../shared/auth/user-route-access-service';
import {Route, Routes} from '@angular/router';
import {DelegationDeleteDialogComponent} from './delegation-delete-dialog.component';
import {Account} from '../../shared/user/account.model';

export const delegationRoute: Route = {
    path: 'delegations',
    component: DelegationComponent,
    data: {
        authorities: [Account.DEMANDEUR, Account.CONSULTANT],
        pageTitle: 'Délégation de droits'
    },
    canActivate: [UserRouteAccessService]
};

export const delegationPopupRoute: Routes = [
    {
        path: 'delegation/:id/delete',
        component: DelegationDeleteDialogComponent,
        data: {
            authorities: [Account.DEMANDEUR, Account.CONSULTANT],
            pageTitle: 'Suppression délégation'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
