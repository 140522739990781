import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Rx';
import { JhiEventManager  } from 'ng-jhipster';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import {BrowserUtil} from '../../shared/browser/browser.util';

import { DocumentHorsDossier } from './document-hors-dossier.model';
import { DocumentHorsDossierService } from './document-hors-dossier.service';

@Component({
    selector: 'dm-document-hors-dossier-detail',
    templateUrl: './document-hors-dossier-detail.component.html'
})
export class DocumentHorsDossierDetailComponent implements OnInit, OnDestroy {

    documentHorsDossier: DocumentHorsDossier;
    documentArray = [];
    handicapShow= false;
    private subscription: Subscription;
    private eventSubscriber: Subscription;
    private date = new Date();
    ieBrowser: boolean;

    constructor(
        private eventManager: JhiEventManager,
        private documentHorsDossierService: DocumentHorsDossierService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInDocumentHorsDossiers();
    }

    load(id) {
        this.documentHorsDossierService.find(id).subscribe((documentHorsDossier) => {
            this.documentHorsDossier = documentHorsDossier;
            this.documentArray.push(documentHorsDossier);
        });
    }
    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInDocumentHorsDossiers() {
        this.eventSubscriber = this.eventManager.subscribe(
            'documentHorsDossierListModification',
            (response) => this.load(this.documentHorsDossier.id)
        );
    }
}
