import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {Fichier} from './file.model';

@Injectable()
export class FileService {
    private resourceUrl = 'api';

    constructor(private http: HttpClient) { }

    downloadPieceJointe(id): Observable<Fichier> {
        return this.http.get(`${this.resourceUrl}/piece-jointes/download/${id}`, {observe: 'response', responseType: 'blob'})
            .map((reponse) => {
                    return new Fichier(reponse.headers.get('File-Name'), new Blob([reponse.body]));
                });
    }

    downloadPiecesJointes(id): Observable<Fichier> {
        return this.http.get(`${this.resourceUrl}/piece-jointes/download/dossier/${id}`, {observe: 'response', responseType: 'blob'})
            .map((reponse) => {
                return new Fichier(reponse.headers.get('File-Name'), new Blob([reponse.body]));
            });
    }

    downloadDocumentHorsDossier(id): Observable<Fichier> {
        return this.http.get(`${this.resourceUrl}/document-hors-dossiers/file/${id}`, {observe: 'response', responseType: 'blob'})
            .map((reponse) => {
                    return new Fichier(reponse.headers.get('File-Name'), new Blob([reponse.body]));
                });
    }

}
