
const enum Civilite {
    'MME',
    'M'

};
export class UtilisateurDm {
    constructor(
        public id?: number,
        public fonction?: string,
        public siret?: string,
        public nomSociete?: string,
        public adresseSociete?: string,
        public telephoneFixe?: string,
        public telephonePortable?: string,
        public civilite?: Civilite,
        public userId?: number,
        public membreHandicap?: boolean,
        public dossierId?: number,
        public delegantId?: number,
        public delegueId?: number,

    ) {
    }
}
