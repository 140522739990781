import {JhiEventManager} from 'ng-jhipster';
import {Observable} from 'rxjs/Observable';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {tap} from "rxjs/internal/operators";
import {Injectable} from "@angular/core";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {},
                (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (!(err.status === 401 && (err.message === '' ||
                            (err.url && err.url.indexOf('api/account') >= 0 )))) {
                        this.eventManager.broadcast( {name: 'medimedApp.httpError', content: err});
                    }
                    return Observable.of(err);
                }
            })
        );
    }

    constructor(private eventManager: JhiEventManager) {
    }

}
