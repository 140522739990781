import { Route } from '@angular/router';

import { DmMetricsMonitoringComponent } from './metrics.component';

export const metricsRoute: Route = {
    path: 'dm-metrics',
    component: DmMetricsMonitoringComponent,
    data: {
        pageTitle: 'Application Metrics'
    }
};
