import {HttpParams} from "@angular/common/http";

export const createRequestOption = (req?: any): HttpParams => {
    const params: HttpParams = new HttpParams();
    if (req) {
        params.set('page', req.page);
        params.set('size', req.size);
        if (req.sort) {
            params.set('sort', req.sort);
        }
        params.set('query', req.query);
    }
    return params;
};
