import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { JhiEventManager } from 'ng-jhipster';

import { AttributDossier } from './attribut-dossier.model';
import { AttributDossierPopupService } from './attribut-dossier-popup.service';
import { AttributDossierService } from './attribut-dossier.service';

@Component({
    selector: 'dm-attribut-dossier-delete-dialog',
    templateUrl: './attribut-dossier-delete-dialog.component.html'
})
export class AttributDossierDeleteDialogComponent {

    attributDossier: AttributDossier;

    constructor(
        private attributDossierService: AttributDossierService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.attributDossierService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'attributDossierListModification',
                content: 'Deleted an attributDossier'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'dm-attribut-dossier-delete-popup',
    template: ''
})
export class AttributDossierDeletePopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private attributDossierPopupService: AttributDossierPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.modalRef = this.attributDossierPopupService
                .open(AttributDossierDeleteDialogComponent, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
