import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {catchError, retry} from "rxjs/internal/operators";
import {throwError} from "rxjs/index";

@Injectable()
export class AccountService {
    constructor(private http: HttpClient) {
    }

    get(): Observable<any> {
        return this.http.get('api/account');
    }

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    save(account: any): Observable<any> {
        return this.http.post('api/account', JSON.stringify(account), this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.errorHandl)
            )
    }

    isRegistrationClosed(): Observable<boolean> {
        return this.http.get('api/account/max-reached').map((res: HttpResponse<any>) => {
            const resText = JSON.stringify(res);
            return resText === 'true';
        });
    }

    // Error handling
    errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
