import {Routes} from '@angular/router';

import {UserRouteAccessService} from '../../shared';

import {DocumentHorsDossierComponent} from './document-hors-dossier.component';
import {DocumentHorsDossierPopupComponent} from './document-hors-dossier-dialog.component';
import {DocumentHorsDossierDeletePopupComponent} from './document-hors-dossier-delete-dialog.component';
import {Account} from '../../shared/user/account.model';

export const documentHorsDossierRoute: Routes = [
    {
        path: 'document-hors-dossier',
        component: DocumentHorsDossierComponent,
        data: {

            authorities: [ Account.MEMBRE_COMITE_DM, Account.ADMINISTRATEUR],
            pageTitle: 'Documents Hors Dossiers'
        },
        canActivate: [UserRouteAccessService]
    }

];

export const documentHorsDossierPopupRoute: Routes = [
    {
        path: 'document-hors-dossier-new',
        component: DocumentHorsDossierPopupComponent,
        data: {

            authorities: [Account.ADMINISTRATEUR],
            pageTitle: 'DocumentHorsDossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'document-hors-dossier/:id/edit',
        component: DocumentHorsDossierPopupComponent,
        data: {
            authorities: [Account.ADMINISTRATEUR],
            pageTitle: 'DocumentHorsDossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'document-hors-dossier/:id/delete',
        component: DocumentHorsDossierDeletePopupComponent,
        data: {
            authorities: [Account.ADMINISTRATEUR],
            pageTitle: 'DocumentHorsDossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }

];
