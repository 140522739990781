import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MedimedSharedModule } from '../../shared';
import { MedimedAdminModule } from '../../admin/admin.module';
import {
    UtilisateurDmService,
    UtilisateurDmPopupService,
    UtilisateurDmComponent,
    UtilisateurDmDetailComponent,
    UtilisateurDmDialogComponent,
    UtilisateurDmPopupComponent,
    UtilisateurDmDeletePopupComponent,
    UtilisateurDmDeleteDialogComponent,
    utilisateurDmRoute,
    utilisateurDmPopupRoute,
} from './';

const ENTITY_STATES = [
    ...utilisateurDmRoute,
    ...utilisateurDmPopupRoute,
];

@NgModule({
    imports: [
        MedimedSharedModule,
        MedimedAdminModule,
        RouterModule.forRoot(ENTITY_STATES, { useHash: true })
    ],
    declarations: [
        UtilisateurDmComponent,
        UtilisateurDmDetailComponent,
        UtilisateurDmDialogComponent,
        UtilisateurDmDeleteDialogComponent,
        UtilisateurDmPopupComponent,
        UtilisateurDmDeletePopupComponent,
    ],
    entryComponents: [
        UtilisateurDmComponent,
        UtilisateurDmDialogComponent,
        UtilisateurDmPopupComponent,
        UtilisateurDmDeleteDialogComponent,
        UtilisateurDmDeletePopupComponent,
    ],
    providers: [
        UtilisateurDmService,
        UtilisateurDmPopupService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedUtilisateurDmModule {}
