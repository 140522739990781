    export class Account {

    // constantes d'autorité
    static DEMANDEUR = 'DEMANDEUR';
    static MEMBRE_COMITE_DM = 'MEMBRE_COMITE_DM';
    static ADMINISTRATEUR = 'ADMINISTRATEUR';
    static CONSULTANT = 'CONSULTANT';
    /*** L'utilisateur est loggé et a un des quatre rôles repertoriés dans l'application */
    static CONNECTE = [Account.DEMANDEUR, Account.CONSULTANT, Account.MEMBRE_COMITE_DM, Account.ADMINISTRATEUR];

    public static getLibelle(lib: string): string {
        switch (lib) {
            case Account.DEMANDEUR :
                return 'Demandeur';
            case Account.CONSULTANT :
                return 'Consultant';
            case Account.MEMBRE_COMITE_DM :
                return 'Membre Comité DM';
            case Account.ADMINISTRATEUR:
                return 'Administrateur';
            default:
                return 'PROFIL INCONNU';
        }
    }

    constructor(public activated: boolean,
                public authorities: string[],
                public email: string,
                public firstName: string,
                public langKey: string,
                public lastName: string,
                public login: string,
                public imageUrl: string) {
    }

}
