import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'spaces', pure: false
})
export class SpacesPipe implements PipeTransform {

    constructor() {
    }
    transform(profil: string): any {
        return (profil.charAt(0).toUpperCase() + profil.slice(1).toLowerCase()).replace(/_/g, ' ');
    }

}
