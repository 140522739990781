import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {JhiDateUtils} from 'ng-jhipster';

import {PieceJointe, TypePieceJointe} from './piece-jointe.model';
import {createRequestOption, ResponseWrapper} from '../../shared';
import {map} from "rxjs/internal/operators";

@Injectable()
export class PieceJointeService {

    public static resourceFileUrl = 'api/piece-jointes/file/';
    private resourceUrl = 'api/piece-jointes';

    constructor(private http: HttpClient, private dateUtils: JhiDateUtils) { }

    create(pieceJointe: PieceJointe): Observable<PieceJointe> {
        const copy = this.convert(pieceJointe);
        return this.http.post(this.resourceUrl, copy).pipe(map((pieceJointe: PieceJointe) => {
            this.convertItemFromServer(pieceJointe);
            return pieceJointe;
        }));
    }

    update(pieceJointe: PieceJointe): Observable<PieceJointe> {
        const copy = this.convert(pieceJointe);
        return this.http.put(this.resourceUrl, copy).pipe(map((pieceJointe: PieceJointe) => {
            this.convertItemFromServer(pieceJointe);
            return pieceJointe;
        }));
    }

    find(id: number): Observable<PieceJointe> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((pieceJointe: PieceJointe) => {
            this.convertItemFromServer(pieceJointe);
            return pieceJointe;
        }));
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createRequestOption(req);
        return this.http.get(this.resourceUrl, {params})
            .map((res: HttpResponse<any>) => this.convertResponse(res));
    }

    delete(id: number): Observable<Response> {
        return this.http.delete<Response>(`${this.resourceUrl}/${id}`);
    }

    getTypePieceJointesByNature(nature: string): Observable<TypePieceJointe[]> {
        return this.http.get<TypePieceJointe[]>(`api/type-piece-jointes/${nature}/nature`);
    }

    private convertResponse(res: HttpResponse<any>): ResponseWrapper {
        const jsonResponse = JSON.stringify(res);
        for (let i = 0; i < jsonResponse.length; i++) {
            this.convertItemFromServer(jsonResponse[i]);
        }
        return new ResponseWrapper(res.headers, jsonResponse);
    }

    private convertItemFromServer(entity: any) {
        entity.dateDepot = this.dateUtils
            .convertDateTimeFromServer(entity.dateDepot);
    }

    private convert(pieceJointe: PieceJointe): PieceJointe {
        const copy: PieceJointe = Object.assign({}, pieceJointe);

        copy.uploader = null;
        copy.hasDropZoneOver = null;
        copy.dateDepot = this.dateUtils.toDate(pieceJointe.dateDepot);
        return copy;
    }
}
