import {Injectable} from '@angular/core';

@Injectable()
export class FiltreUtil {

    constructor() { }

    public filtreTextContains(champFiltre: string, champ: string): boolean {
        return (champFiltre === undefined || champFiltre === '' || champFiltre == null) ||
            (champ !== null && champ.toUpperCase().indexOf(champFiltre.toUpperCase()) !== -1);
    }

    public filtreTextEquals(champFiltre: string, champ: string): boolean {
        return (champFiltre === undefined || champFiltre === '' || champFiltre == null) ||
            (champ !== null && champ.toUpperCase() === champFiltre.toUpperCase());
    }

}
