import { FileUploader } from 'ng2-file-upload';

export class DocumentHorsDossier {
    constructor(
        public id?: number,
        public dateDepot?: any,
        public nomFichier?: string,
        public titre?: string,
        public description?: string,
        public handicap?: boolean,
        public repertoire?: string,
        /*upload des documentHorsDossier*/
        public uploader?: FileUploader,
        public hasDropZoneOver?: boolean,
        public archivage?: boolean
    ) {
        this.handicap = false;
        this.archivage = false;
    }
}
