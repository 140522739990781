import {JhiEventManager} from 'ng-jhipster';
import {Observable} from 'rxjs/Observable';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {tap} from "rxjs/internal/operators";
import {Injectable} from "@angular/core";

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if(event instanceof HttpResponse) {
                        const alert = event.headers.get("x-medimedapp-alert");
                        if (alert) {
                            this.eventManager.broadcast( {name: 'medimedApp.httpNotification', content: alert});
                        }
                    }
                }
            ));
    }

    constructor(private eventManager: JhiEventManager) {
    }
}
