import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs/Rx';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {FileService} from '../../shared/file/file.service';
import {Fichier} from '../../shared/file/file.model';

import {DocumentHorsDossier} from './document-hors-dossier.model';
import {DocumentHorsDossierService} from './document-hors-dossier.service';
import {ITEMS_PER_PAGE, Principal, ResponseWrapper} from '../../shared';
import {DocumentHorsDossierCommentaires} from './document-hors-dossier.commentaires.model';
import {DocumentHorsDossierFilter} from './document-hors-dossier.filter.model';
import {DocumentHorsDossierRepertoire} from './document-hors-dossier.repertoire.model';
import {ConfirmationService} from 'primeng/primeng';
import {Pageable} from '../../shared/util/pageable.model';
import fileSaver = require('file-saver');

@Component({
    selector: 'dm-document-hors-dossier',
    templateUrl: './document-hors-dossier.component.html'
})
export class DocumentHorsDossierComponent implements OnInit, OnDestroy {

    documentHorsDossier: DocumentHorsDossier;
    documentHorsDossiers: DocumentHorsDossier[];
    documentHorsDossiersPJ: DocumentHorsDossier[];
    currentAccount: any;
    eventSubscriber: Subscription;
    itemsPerPage: number;
    links: any;
    page: any;
    predicate: any;
    reverse: any;
    baseSort = true;
    handicapShow = false;
    /* variable pour cacher la colonne handicap*/
    repertoire: DocumentHorsDossierRepertoire;
    commentaires: DocumentHorsDossierCommentaires;
    filter: DocumentHorsDossierFilter;
    isSaving: boolean;
    repertoireHorsDossiers = []; // tableau qui va servir pour ne pas afficher les doublons dans le select repertoire
    vueAuthorities: any;
    archivageShow: boolean;
    pageable: Pageable; // objet créé pour la pagination
    allowedExtensions: string[];

    constructor(private documentHorsDossierService: DocumentHorsDossierService,
                private alertService: JhiAlertService,
                private eventManager: JhiEventManager,
                private principal: Principal,
                private confirmationService: ConfirmationService,
                private fileService: FileService) {
        this.documentHorsDossiers = [];
        this.documentHorsDossiersPJ = [];
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.page = 1;
        this.links = {
            last: 0
        };
        this.predicate = 'id';
        this.reverse = true;
        this.archivageShow = false;
        this.pageable = {
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            totalSize: 0
        };

    }

    loadAll() {
        this.documentHorsDossierService.query({
            page: this.page,
            size: this.itemsPerPage,
            sort: this.sort()
        }).subscribe(
            (res: ResponseWrapper) => this.onSuccess(res.json),
            (res: ResponseWrapper) => this.onError(res.json)
        );
    }

    reset() {
        this.page = 1;
        this.documentHorsDossiers = [];
        this.loadAll();
    }

    hide() {
        this.documentHorsDossierService.downloadHide = true;
    }

    show() {
        this.documentHorsDossierService.downloadHide = false;
    }

    loadPage(page) {
        this.page = page;
        this.loadAll();
    }

    ngOnInit() {
        this.loadAll();
        this.documentHorsDossierService.getAllowedExtensions().subscribe((map) => {
            this.allowedExtensions = map.extensions;
        });

        this.principal.identity().then((account) => {
            this.currentAccount = account;
            this.vueAuthorities = account.authorities[0];
            this.handicapShow = account.utilisateurDm.membreHandicap;
        });
        this.registerChangeInDocumentHorsDossiers();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: DocumentHorsDossier) {
        return item.id;
    }

    registerChangeInDocumentHorsDossiers() {
        this.eventSubscriber = this.eventManager.subscribe('documentHorsDossierListModification', (response) => this.reset());
    }

    pageChange($event) {
        this.pageable.page = $event;
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    tabSort(attr: string) {
        if (this.baseSort) {
            this.documentHorsDossiers.sort((dhd1, dhd2) => {
                if (dhd1[attr] < dhd2[attr]) {
                    return -1;
                } else if (dhd1[attr] > dhd2[attr]) {
                    return 1;
                }
                return 0;
            });
            this.baseSort = false;
        } else if (!this.baseSort) {
            this.documentHorsDossiers.reverse();
            this.baseSort = true;
        }
    }

    sortArchive() {
        this.archivageShow = !this.archivageShow;
        this.repertoireHorsDossiers = this.getListeRepertoires(this.documentHorsDossiers);
    }

    /**
     * Génère la liste des différents répertoires en fonction des docHD passés en paramètre
     * @param {Array<DocumentHorsDossier>} documentHorsDossiers
     * @returns {Array<string>}
     */
    private getListeRepertoires(documentHorsDossiers: Array<DocumentHorsDossier>): Array<string> {
        const repertoireNamesSet = new Set<string>();
        documentHorsDossiers.forEach((dhd: DocumentHorsDossier) => repertoireNamesSet.add(dhd.repertoire));
        return Array.from(repertoireNamesSet).sort((a, b) => a.localeCompare(b));
    }

    private onSuccess(documentHorsDossiers: DocumentHorsDossier[]) {
        this.documentHorsDossiers = documentHorsDossiers;
        this.pageable.totalSize = this.documentHorsDossiers.length;
        this.repertoireHorsDossiers = this.getListeRepertoires(this.documentHorsDossiers);
    }

    private onSaveSuccess(result: DocumentHorsDossier) {
        this.documentHorsDossier = result;
        this.eventManager.broadcast({name: 'documentHorsDossierListModification', content: 'OK'});
        this.isSaving = false;
        this.archivageShow = false;
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }
    //
    // private subscribeToSaveResponsePJ(result: Observable<DocumentHorsDossier>) {
    //     result.subscribe((res: DocumentHorsDossier) => this.documentHorsDossiersPJ.push(result), (res: Response) => this.onSaveError(res));
    // }
    //
    // private subscribeToSaveResponse(result: Observable<DocumentHorsDossier>) {
    //     result.subscribe((res: DocumentHorsDossier) =>
    //         this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
    // }

    private onSaveError(error) {
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.isSaving = false;
        this.onError(error);
    }

    download(id: number) {
        this.fileService.downloadDocumentHorsDossier(id).subscribe((fichier: Fichier) => {
            fileSaver.saveAs(fichier.blob, fichier.name);
        });
    }

    /**
     * Envoi de la notification de nouveaux documents ajoutés aux membres DM
     */
    alerterUsers() {
        this.confirmationService.confirm({
            message: 'Voulez-vous alerter les membre du comité DM de l\'ajout de documents?',
            accept: () => {
                this.documentHorsDossierService.sendAlert().subscribe((res: any) => {
                });
            }
        });
    }
}
