import {Pipe, PipeTransform} from '@angular/core';
import {Dossier} from './dossier.model';
import {DossierFiltre} from './dossier-filtre.model';
import {FiltreUtil} from '../../shared/util/filtre.util';

@Pipe({
  name: 'dossierPipe', pure: false
})
export class DossierPipe implements PipeTransform {

    constructor(private filtreUtil: FiltreUtil) {
    }

    transform(dossiers: Dossier[], dossierFiltre: DossierFiltre): any {
        if (!dossiers || !dossierFiltre) {
            return dossiers;
        }

        // filter items array, items which match and return true will be kept, false will be filtered out
        return dossiers.filter((dossier) => {
            return this.filtreUtil.filtreTextContains(dossierFiltre.nomSociete, dossier.nomSociete)
            && this.filtreUtil.filtreTextContains(dossierFiltre.nomProduit, dossier.nomProduit)
            && this.filtreUtil.filtreTextContains(dossierFiltre.numDossier, dossier.numDossier)
            && this.filtreUtil.filtreTextEquals(dossierFiltre.etat, dossier.etat.toString())
            && this.filtreUtil.filtreTextEquals(dossierFiltre.nature, dossier.nature.toString());
        });
    }

}
