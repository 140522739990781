import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dm-filtre-tpl',
  templateUrl: './filtre-tpl.component.html',
  styles: []
})
export class FiltreTplComponent implements OnInit {
    @Input() titre: string;

  constructor() {
  }

  ngOnInit() {
  }

}
