import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MedimedSharedModule } from '../shared';

import { mentionslegalesRoute, MentionslegalesComponent } from './';

@NgModule({
    imports: [
        MedimedSharedModule,
        RouterModule.forRoot([ mentionslegalesRoute ], { useHash: true })
    ],
    declarations: [
        MentionslegalesComponent,
    ],
    entryComponents: [
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedMentionslegalesModule {}
