import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

import { AttributDossier } from './attribut-dossier.model';
import { ResponseWrapper, createRequestOption } from '../../shared';

@Injectable()
export class AttributDossierService {

    private resourceUrl = 'api/attribut-dossiers';

    constructor(private http: HttpClient) { }

    create(attributDossier: AttributDossier): Observable<AttributDossier> {
        const copy = this.convert(attributDossier);
        return this.http.post(this.resourceUrl, copy);
    }

    update(attributDossier: AttributDossier): Observable<AttributDossier> {
        const copy = this.convert(attributDossier);
        return this.http.put(this.resourceUrl, copy);
    }

    find(id: number): Observable<AttributDossier> {
        return this.http.get(`${this.resourceUrl}/${id}`);
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createRequestOption(req);
        return this.http.get(this.resourceUrl, {params})
            .map((res: HttpResponse<any>) => this.convertResponse(res));
    }

    delete(id: number): Observable<Response> {
        return this.http.delete<Response>(`${this.resourceUrl}/${id}`);
    }

    private convertResponse(res: HttpResponse<any>): ResponseWrapper {
        return new ResponseWrapper(res.headers, res);
    }

    private convert(attributDossier: AttributDossier): AttributDossier {
        const copy: AttributDossier = Object.assign({}, attributDossier);
        return copy;
    }
}
