import { Injectable, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { DocumentHorsDossier } from './document-hors-dossier.model';
import { DocumentHorsDossierService } from './document-hors-dossier.service';
import {DocumentHorsDossierDialogComponent} from "./document-hors-dossier-dialog.component";
@Injectable()
export class DocumentHorsDossierPopupService {
    private isOpen = false;
    constructor(
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private router: Router,
        private documentHorsDossierService: DocumentHorsDossierService

    ) {}

    open(component: any, id?: number | any): NgbModalRef {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;

        if (id) {
            this.documentHorsDossierService.find(id).subscribe((documentHorsDossier) => {
                documentHorsDossier.dateDepot = this.datePipe
                    .transform(documentHorsDossier.dateDepot, 'yyyy-MM-ddThh:mm');
                this.documentHorsDossierModalRef(component, documentHorsDossier);
            });
        } else {
            return this.documentHorsDossierModalRef(component, new DocumentHorsDossier());
        }
    }

    documentHorsDossierModalRef(component: any, documentHorsDossier: DocumentHorsDossier): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static'});
        modalRef.componentInstance.documentHorsDossier = documentHorsDossier;
        modalRef.result.then((result) => {
            this.router.navigate([{ outlets: { popup: null }}], { replaceUrl: true });
            this.isOpen = false;
        }, (reason) => {
            this.router.navigate([{ outlets: { popup: null }}], { replaceUrl: true });
            this.isOpen = false;
        });
        return modalRef;
    }
}
