import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {JhiDateUtils} from 'ng-jhipster';

import {DocumentHorsDossier} from './document-hors-dossier.model';
import {createRequestOption, ResponseWrapper} from '../../shared';
import {map} from "rxjs/internal/operators";

@Injectable()
export class DocumentHorsDossierService {
    public static resourceFileUrl = 'api/document-hors-dossiers/file/';
    public static resourceCheckFileUrl = 'api/document-hors-dossiers/checkFile';
    private resourceUrl = 'api/document-hors-dossiers';
    public popupHorsDossier: boolean;
    public downloadHide: boolean;
    constructor(private http: HttpClient, private dateUtils: JhiDateUtils) { }

    create(documentHorsDossier: DocumentHorsDossier): Observable<DocumentHorsDossier> {
        const copy = this.convert(documentHorsDossier);
        return this.http.post(this.resourceUrl, copy).pipe(map((documentHorsDossier: DocumentHorsDossier) => {
            this.convertItemFromServer(documentHorsDossier);
            return documentHorsDossier;
        }));
    }

    update(documentHorsDossier: DocumentHorsDossier): Observable<DocumentHorsDossier> {
        const copy = this.convert(documentHorsDossier);
        return this.http.put(this.resourceUrl, copy).pipe(map((documentHorsDossier: DocumentHorsDossier) => {
            this.convertItemFromServer(documentHorsDossier);
            return documentHorsDossier;
        }));
    }

    find(id: number): Observable<DocumentHorsDossier> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((documentHorsDossier: DocumentHorsDossier) => {
            this.convertItemFromServer(documentHorsDossier);
            return documentHorsDossier;
        }));
    }

    getAllowedExtensions(): Observable<any> {
        return this.http.get(`${this.resourceUrl}/extensions-autorisees`).pipe(map((documentHorsDossier: DocumentHorsDossier) => {
            this.convertItemFromServer(documentHorsDossier);
            return documentHorsDossier;
        }));
    }

    query(req?: any): Observable<any> {
        const params = createRequestOption(req);
        return this.http.get(this.resourceUrl, {params})
            .map((res) => this.convertResponse(res));
    }

    delete(id: number): Observable<Response> {
        return this.http.delete<Response>(`${this.resourceUrl}/${id}`);
    }

    sendAlert(): Observable<Response> {
        return this.http.get<Response>(`${this.resourceUrl}/alerter`);
    }

    private convertResponse(res: any) {
        for (let i = 0; i < res.length; i++) {
            this.convertItemFromServer(res[i]);
        }
        return new ResponseWrapper(res.headers, res);
    }

    private convertItemFromServer(entity: any) {
        entity.dateDepot = this.dateUtils
            .convertDateTimeFromServer(entity.dateDepot);
    }

    private convert(documentHorsDossier: DocumentHorsDossier): DocumentHorsDossier {
        const copy: DocumentHorsDossier = Object.assign({}, documentHorsDossier);
        copy.dateDepot = this.dateUtils.toDate(documentHorsDossier.dateDepot);
        copy.uploader = null;
        copy.hasDropZoneOver = null;
        return copy;
    }

}
