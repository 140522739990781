import {Component, OnInit} from '@angular/core';

import {Principal} from '../../shared';
import {Password} from './password.service';

@Component({
    selector: 'dm-password',
    templateUrl: './password.component.html'
})
export class PasswordComponent implements OnInit {
    doNotMatch: string;
    error: string;
    success: string;
    account: any;
    password: string;
    confirmPassword: string;
    oldPassword: string;

    constructor(
        private passwordService: Password,
        private principal: Principal
    ) {
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
            this.account = account;
        });
    }

    changePassword() {
        if (this.password !== this.confirmPassword) {
            this.error = null;
            this.success = null;
            this.doNotMatch = 'ERROR';
        } else {
            this.doNotMatch = null;
            const ancien =  this.oldPassword;
            const nouveau = this.password;

            this.passwordService.save({ancien, nouveau}).subscribe(() => {
                this.error = null;
                this.success = 'OK';
                // le pw n'est plus considéré comme expiré
                this.principal.updatePasswordExpire(false);
            }, (msg) => {
                this.success = null;
            });
        }
    }
}
