import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpClient} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
    constructor(
        private http: HttpClient,
        private $localStorage: LocalStorageService,
        private $sessionStorage: SessionStorageService
    ) {
    }

    getToken() {
        return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    }

    login(credentials): Observable<any> {

        const data = {
            username: credentials.username,
            password: credentials.password
        };
        return this.http.post('api/authenticate', data).map(authenticateSuccess.bind(this));

        function authenticateSuccess(resp) {
            const jwt = resp.id_token;
            this.storeAuthenticationToken(jwt);
            return jwt;
            // const bearerToken = resp.headers.get('Authorization');
            // if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
            //     const jwt = bearerToken.slice(7, bearerToken.length);
            //     this.storeAuthenticationToken(jwt);
            //     return jwt;
            // }
        }
    }

    loginWithToken(jwt) {
        if (jwt) {
            this.storeAuthenticationToken(jwt);
            return Promise.resolve(jwt);
        } else {
            return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        }
    }

    storeAuthenticationToken(jwt) {
        this.$sessionStorage.store('authenticationToken', jwt);
    }

    logout(): Observable<any> {
        return new Observable((observer) => {
            this.$localStorage.clear('authenticationToken');
            this.$sessionStorage.clear('authenticationToken');
            observer.complete();
        });
    }
}
