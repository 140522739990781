import {FileUploader} from 'ng2-file-upload';

export class PieceJointe {

    static EXTENSION_WORD: string[] = ['docx', 'doc', 'rtf', 'odt', 'txt'];
    static EXTENSION_PDF: string[] = ['pdf'];

    constructor(
        public id?: number,
        public type?: TypePieceJointe,
        public typeObligatoire?: boolean,
        public typeLibelle?: string,
        public typeFormat?: string,
        public dateDepot?: any,
        public nomFichier?: string,
        public nomStockage?: string,
        public dossierId?: number,
        // Propriétés d'upload de fichiers
        public uploader?: FileUploader,
        public hasDropZoneOver?: boolean
    ) {
    }
}

export class TypePieceJointe {
    constructor(
        public name?: string,
        public label?: string,
        public obligatoire?: boolean,
        public authorities?: string[]
    ) {
    }
}
