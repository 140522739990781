import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'dm-validation',
    templateUrl: './validation.component.html',
    styles: []
})
export class ValidationComponent implements OnInit {

    @Input() input: Input;
    @Input() max: number;
    @Input() min: number;
    @Input() passwordPattern: boolean;
    @Input() siretPattern: boolean;

    constructor() {
    }

    ngOnInit() {

    }

}
