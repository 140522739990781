import {Pipe, PipeTransform} from '@angular/core';
import {DocumentHorsDossier} from './document-hors-dossier.model';
import {DocumentHorsDossierCommentaires} from './document-hors-dossier.commentaires.model';
import {DocumentHorsDossierFilter} from './document-hors-dossier.filter.model';
import {DocumentHorsDossierRepertoire} from './document-hors-dossier.repertoire.model';

@Pipe({
    name: 'documentHorsDossierPipe', pure: false
})
export class DocumentHorsDossierPipe implements PipeTransform {
    transform(documentHorsDossiers: DocumentHorsDossier[], filterString: DocumentHorsDossierFilter, commentaires: DocumentHorsDossierCommentaires,
              repertoire: DocumentHorsDossierRepertoire, archivageShow: any): any {

        if (((!filterString) || (filterString === '')) && ((!commentaires) || (commentaires === '')) && ((!repertoire) || (repertoire === '')) && (archivageShow === true)) {
            return documentHorsDossiers;
        } else if ((((!filterString) || (filterString === ''))
                && ((!commentaires) || (commentaires === ''))
                && ((!repertoire) || (repertoire === ''))
                && (archivageShow === false))) {
            return documentHorsDossiers.filter((document) => document.archivage === null || document.archivage === false);
        } else {
            return documentHorsDossiers.filter((document) => this.filterSearch(document, filterString, commentaires, repertoire, archivageShow));
        }
    }

    filterSearch(document, filter, commentaires, repertoire, archivageShow): boolean {
        if (!archivageShow) {
            return ((document.nomFichier.indexOf(filter) !== -1) && (filter !== '') && (document.archivage === archivageShow))
                || ((document.description.indexOf(commentaires) !== -1) && (commentaires !== '') && (document.archivage === archivageShow))
                || ((document.repertoire.indexOf(repertoire) !== -1) && (repertoire !== '') && (document.archivage === archivageShow));
        }
        if (archivageShow) {
            return ((document.nomFichier.indexOf(filter) !== -1) && (filter !== ''))
                || ((document.description.indexOf(commentaires) !== -1) && (commentaires !== ''))
                || ((document.repertoire.indexOf(repertoire) !== -1) && (repertoire !== ''));

        }
    }
}
