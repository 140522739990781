import {Observable} from 'rxjs/Observable';
import {Injectable, Injector} from '@angular/core';
import {LoginService} from '../../shared/login/login.service';
import {Router} from '@angular/router';
import {LoginModalService} from '../../shared';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {tap} from "rxjs/internal/operators";

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {},
                (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        const loginService: LoginService = this.injector.get(LoginService);
                        const router: Router = this.injector.get(Router);
                        const loginModalService: LoginModalService = this.injector.get(LoginModalService);
                        loginService.logout();
                        if (router.routerState.snapshot.url !== '/' && router.routerState.snapshot.url !== '/accessdenied') {
                            router.navigate(['accessdenied']).then(() => {
                                // only show the login dialog, if the user hasn't logged in yet
                                loginModalService.open();
                            });
                        } else if (router.routerState.snapshot.url !== '/') {
                            return Observable.of(err);
                        }
                    } else {
                        return Observable.of(err);
                    }
                }
            })
        );
    }

    constructor(public injector: Injector) {
    }

}
