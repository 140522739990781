import {AfterViewInit, Component, ElementRef, OnInit, Renderer} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {JhiEventManager} from 'ng-jhipster';

import {LoginService} from './login.service';
import {StateStorageService} from '../auth/state-storage.service';

@Component({
    selector: 'dm-login-modal',
    templateUrl: './login.component.html'
})
export class DmLoginModalComponent implements OnInit, AfterViewInit {
    authenticationError: boolean;
    password: string;
    username: string;
    credentials: any;
    msgErreur: string;

    constructor(private eventManager: JhiEventManager,
                private loginService: LoginService,
                private stateStorageService: StateStorageService,
                private elementRef: ElementRef,
                private renderer: Renderer,
                private router: Router,
                public activeModal: NgbActiveModal) {
        this.credentials = {};
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#username'), 'focus', []);
    }

    cancel() {
        this.credentials = {
            username: null,
            password: null,
        };
        this.authenticationError = false;
        this.activeModal.dismiss('cancel');
    }

    login() {
        this.loginService.login({
            username: this.username,
            password: this.password,
        }).then(() => {
            this.authenticationError = false;
            this.activeModal.dismiss('login success');
            this.router.navigate(['']);

            this.eventManager.broadcast({
                name: 'authenticationSuccess',
                content: 'Sending Authentication Success'
            });

            // // previousState was set in the authExpiredInterceptor before being redirected to login modal.
            // // since login is succesful, go to stored previousState and clear previousState
            const redirect = this.stateStorageService.getUrl();
            if (redirect) {
                this.router.navigate([redirect]);
            }
        }).catch((response) => {
            const msg = response.error.AuthenticationException;
            if (msg) {
                this.msgErreur = msg;
                this.authenticationError = false;
            } else {
                this.msgErreur = null;
                this.authenticationError = true;
            }

        });
    }

    register() {
        this.activeModal.dismiss('to state register');
        this.router.navigate(['/register']);
    }

    requestResetPassword() {
        this.activeModal.dismiss('to state requestReset');
        this.router.navigate(['/reset', 'request']);
    }
}
