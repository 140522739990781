import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MedimedSharedModule} from '../../shared';
import {
    DossierComponent,
    DossierDeleteDialogComponent,
    DossierDeletePopupComponent,
    DossierDetailComponent,
    DossierPipe,
    DossierPopupComponent,
    dossierPopupRoute,
    DossierPopupService,
    dossierRoute,
    DossierService
} from './';
import {PieceJointeListComponent} from './piece-jointe-list.component';
import {DossierNewComponent} from './dossier-new.component';
import {FileUploadModule} from 'ng2-file-upload';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    CalendarModule,
    CheckboxModule,
    ConfirmationService,
    ConfirmDialogModule,
    DataTableModule,
    DialogModule,
    DropdownModule,
    InputMaskModule,
    MultiSelectModule,
    PaginatorModule,
    SharedModule,
    TriStateCheckboxModule
} from 'primeng/primeng';
import {TimelineComponent} from './timeline/timeline.component';
import {DossierArchivageDialogComponent} from './dossier-archivage-dialog.component';

const ENTITY_STATES = [
    ...dossierRoute,
    ...dossierPopupRoute,
];

@NgModule({
    imports: [
        MedimedSharedModule,
        FileUploadModule,
        BrowserAnimationsModule,
        PaginatorModule,
        MultiSelectModule,
        DropdownModule,
        TriStateCheckboxModule,
        InputMaskModule,
        DataTableModule,
        SharedModule,
        CalendarModule,
        ConfirmDialogModule,
        CheckboxModule,
        RouterModule.forRoot(ENTITY_STATES, {useHash: true}),
        DialogModule
    ],
    declarations: [
        DossierComponent,
        DossierDetailComponent,
        DossierArchivageDialogComponent,
        DossierDeleteDialogComponent,
        DossierPopupComponent,
        DossierDeletePopupComponent,
        DossierPipe,
        PieceJointeListComponent,
        DossierNewComponent,
        TimelineComponent,

    ],
    entryComponents: [
        DossierComponent,
        DossierArchivageDialogComponent,
        DossierPopupComponent,
        DossierDeleteDialogComponent,
        DossierDeletePopupComponent,
        DossierNewComponent
    ],
    providers: [
        DossierService,
        DossierPopupService,
        ConfirmationService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedDossierModule {}
