import {Pipe, PipeTransform} from '@angular/core';
import {Pageable} from './pageable.model';

@Pipe({
    name: 'PaginationPipe', pure: false
})
export class PaginationPipe implements PipeTransform {

    transform(elAfiltrer: [any], pageable: Pageable): any {

        pageable.totalSize = elAfiltrer.length;
        return elAfiltrer.slice(((pageable.page - 1) * pageable.itemsPerPage), (pageable.itemsPerPage * pageable.page));
    }
}
