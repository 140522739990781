import {Routes} from '@angular/router';
import {
    activateRoute,
    delegationPopupRoute,
    delegationRoute,
    passwordResetFinishRoute,
    passwordResetInitRoute,
    passwordRoute,
    registerRoute,
    settingsRoute
} from './';

const ACCOUNT_ROUTES = [
    activateRoute,
    passwordRoute,
    passwordResetFinishRoute,
    passwordResetInitRoute,
    registerRoute,
    settingsRoute,
    delegationRoute
];

export const accountState: Routes = [{
    path: '',
    children: ACCOUNT_ROUTES
},
    ...delegationPopupRoute];
