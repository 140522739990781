import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class Activate {

    constructor(private http: HttpClient) {}

    get(key: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('key', key);

        return this.http.get('api/activate', {
            params
        }).map((res: Response) => res);
    }
}
