import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class PasswordResetInit {

    constructor(private http: HttpClient) {}

    save(mail: string): Observable<string> {
        const requestOptions: Object = {
            responseType: 'text'
        };

        return this.http.post<string>('api/account/reset_password/init', mail, requestOptions);

    }
}
