import {Routes} from '@angular/router';

import {UserRouteAccessService} from '../../shared';

import {DossierComponent} from './dossier.component';
import {DossierNewComponent} from './dossier-new.component';
import {DossierDeletePopupComponent} from './dossier-delete-dialog.component';
import {Account} from '../../shared/user/account.model';
import {DossierArchivageDialogComponent} from './dossier-archivage-dialog.component';

export const dossierRoute: Routes = [
    {
        path: 'dossier',
        component: DossierComponent,
        data: {
            authorities: Account.CONNECTE,
            pageTitle: 'Gestion des dossiers'
        },
        canActivate: [UserRouteAccessService]
    }, {
        path: 'dossiers/new',
        component: DossierNewComponent,
        data: {
            authorities: [Account.DEMANDEUR, Account.CONSULTANT],
            pageTitle: 'Créer un Dossier'
        },
        canActivate: [UserRouteAccessService]
    }, {
        path: 'dossiers/:id/edit',
        component: DossierNewComponent,
        data: {
            authorities: Account.CONNECTE,
            pageTitle: 'Edition d\'un Dossier'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const dossierPopupRoute: Routes = [
    {
        path: 'dossier/:id/delete',
        component: DossierDeletePopupComponent,
        data: {
            authorities: [Account.ADMINISTRATEUR, Account.DEMANDEUR, Account.CONSULTANT],
            pageTitle: 'Dossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'dossier/:id/archiver',
        component: DossierArchivageDialogComponent,
        data: {
            authorities: [Account.ADMINISTRATEUR, Account.DEMANDEUR, Account.CONSULTANT],
            pageTitle: 'Dossiers'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
