import {Observable} from 'rxjs/Observable';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {LocalStorageService, SessionStorageService} from "ngx-webstorage";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let jsonReq: HttpRequest<any> = req.clone({
            setHeaders:{
                Authorization : `Bearer ${this.sessionStorage.retrieve("authenticationToken")}`
            }
        });

        return next.handle(jsonReq);

        // // Get the auth token from the service.
        // const authToken = this.auth.getToken();
        // // Clone the request and replace the original headers with
        // // cloned headers, updated with the authorization.
        // const authReq = req.clone({
        //     headers: req.headers.set('Authorization', authToken)
        // });
        // // send cloned request with header to the next handler.
        // return next.handle(authReq);
        //
        // let currentUser = this.auth.currentUserValue;
        // if (currentUser && currentUser.token) {
        //     req = req.clone({
        //         setHeaders: {
        //             Authorization: `Bearer ${currentUser.token}`
        //         }
        //     });
        // }
        //
        // return next.handle(req);

    }

    constructor( private localStorage: LocalStorageService,
                 private sessionStorage: SessionStorageService) { }

}
