import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {DelegationService} from '../../shared/delegation/delegation.service';
import {DelegationPopupService} from './delegation-popup.service';
import {User} from '../../shared/user/user.model';

@Component({
    selector: 'dm-delegation-mgmt-delete-dialog',
    templateUrl: './delegation-delete-dialog.component.html'
})
export class DelegationMgmtDeleteDialogComponent {
    user: User;
    id: number;

    constructor(
        private delegationService: DelegationService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id) {
        this.delegationService.supprimer(id).subscribe((response) => {
            this.eventManager.broadcast({ name: 'delegationListModification',
                content: 'Deleted a delegation'});
            this.activeModal.dismiss(true);
        });
    }

}

@Component({
    selector: 'dm-delegation-delete-dialog',
    template: ''
})
export class DelegationDeleteDialogComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private delegationModalService: DelegationPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            const user = new User();
            user.id = params.id;
            this.modalRef = this.delegationModalService.open(DelegationMgmtDeleteDialogComponent, user);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
