import { Component } from '@angular/core';

@Component({
    selector: 'dm-docs',
    templateUrl: './docs.component.html'
})
export class DmDocsComponent {
    constructor(
    ) {
    }
}
