import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {Dossier} from './dossier.model';
import {DossierPopupService} from './dossier-popup.service';
import {DossierService} from './dossier.service';
import {IPrimeNgConfigCalendarFr, PRIMENG_CONFIG_CALENDAR_FR} from '../../config/app.primeng.config.calendar.fr';

@Component({
    selector: 'dm-dossier-dialog',
    templateUrl: './dossier-archivage-dialog.component.html'
})
export class DossierArchivageDialogComponent implements OnInit {

    @Input()
    dossier: Dossier;

    authorities: any[];
    isSaving: boolean;
    routeSub: any;
    modalRef: NgbModalRef;
    today: Date = new Date();

    constructor(public activeModal: NgbActiveModal,
                private alertService: JhiAlertService,
                private dossierService: DossierService,
                @Inject(PRIMENG_CONFIG_CALENDAR_FR) public fr: IPrimeNgConfigCalendarFr,
                private eventManager: JhiEventManager) {
    }

    ngOnInit() {
        this.isSaving = false;
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        this.dossierService.archiver(this.dossier).subscribe((dossier: Dossier) => {
            this.isSaving = false;
            this.activeModal.dismiss('cancel');
            this.eventManager.broadcast({name: 'dossierListModification', content: dossier});
        });
    }

    onSaveError(error) {
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.isSaving = false;
        this.onError(error);
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }

}

@Component({
    selector: 'dm-dossier-popup',
    template: ''
})
export class DossierPopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(private route: ActivatedRoute,
                private dossierPopupService: DossierPopupService) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.modalRef = this.dossierPopupService
                .open(DossierArchivageDialogComponent, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
