import {Component, OnDestroy, OnInit} from '@angular/core';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {Principal, User} from '../../shared';
import {DelegationService} from '../../shared/delegation/delegation.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DelegationPopupService} from './delegation-popup.service';
import {DelegationAddDialogComponent} from './delegation-add-dialog.component';
import {AlertErrorService} from '../../shared/alert/alert-error.service';

@Component({
    selector: 'dm-delegation',
    templateUrl: './delegation.component.html'
})
export class DelegationComponent implements OnInit, OnDestroy {

    email: string;
    /** User trouvé par son email */
    user: User;
    /** Tableau des délégués du user courant*/
    userDelegues: User[];
    modalRef: NgbModalRef;

    constructor(private alertErrorService: AlertErrorService,
                private alertService: JhiAlertService,
                private principal: Principal,
                private eventManager: JhiEventManager,
                private delegationService: DelegationService,
                private delegationPopupService: DelegationPopupService) {
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
        });

        this.loadAll();
        this.registerChangeInDelegations();

    }

    ngOnDestroy() {

    }

    registerChangeInDelegations() {
        this.eventManager.subscribe('delegationListModification', (response) => {
            this.loadAll();
            this.email = null;
        });
    }

    setActive(user, isActivated) {
        user.activated = isActivated;

    }

    loadAll() {
        this.delegationService.findDelegues().subscribe((delegues) => {
            this.userDelegues = delegues;
        });
    }

    trackIdentity(index, item: User) {
        return item.id;
    }

    sort() {

    }

    private onError(error) {
        this.alertService.error(error.error, error.message, null);
    }

    public rechercherEmail() {
        this.delegationService.getByEmail(this.email).subscribe((user) => {
            this.alertErrorService.clear();
            this.delegationPopupService.open(DelegationAddDialogComponent, user);
        });
    }

    public retirerDelegation(user: User) {
        this.delegationService.supprimer(user.id).subscribe((res) => {
            this.eventManager.broadcast({
                name: 'delegationListModification',
                content: `Ajout d'un délégué`
            });
        });
    }
}
