import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {Register} from './register.service';
import {LoginModalService} from '../../shared';
import {ListeReferenceService} from '../../shared/liste-reference/liste-reference.service';
import {Account} from '../../shared/user/account.model';
import {AccountService} from '../../shared/auth/account.service';
import {BrowserUtil} from '../../shared/browser/browser.util';
import {ListeRefElement} from '../../shared/liste-reference/liste-ref-element.model';

@Component({
    selector: 'dm-register',
    templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit, AfterViewInit {

    ieBrowser: boolean;
    confirmPassword: string;
    doNotMatch: string;
    error: string;
    errorEmailExists: string;
    errorUserExists: string;
    /** Inscription fermées en fonction le nb d'inscription du jour  */
    registrationClosed: boolean;
    registerAccount: any;
    success: boolean;
    modalRef: NgbModalRef;
    type: string;
    organisation = {type: 'neutral'};
    // Listes
    typeComptes: ListeRefElement[] = [];
    // Const
    DEMANDEUR = Account.DEMANDEUR;
    MEMBRE_COMITE_DM = Account.MEMBRE_COMITE_DM;
    ADMINISTRATEUR = Account.ADMINISTRATEUR;
    CONSULTANT = Account.CONSULTANT;

    constructor(private loginModalService: LoginModalService,
                private registerService: Register,
                private accountService: AccountService,
                private browserUtil: BrowserUtil,
                private listeReferenceService: ListeReferenceService) {
    }

    ngOnInit() {
        this.ieBrowser = this.browserUtil.isIE();
        this.success = false;
        this.registerAccount = {
            utilisateurDm: {}
        };

        // inscription fermées?
        this.accountService.isRegistrationClosed().subscribe((isClosed: boolean) => this.registrationClosed = isClosed);

        // liste des types de compte
        this.listeReferenceService.get(this.listeReferenceService.TYPE_COMPTE).subscribe((typeComptes: ListeRefElement[]) => {
            // 0041273 Retirer les profils ADMIN
            typeComptes.forEach((element) => {
                if (element.value !== Account.ADMINISTRATEUR) {
                    this.typeComptes.push(element);
					this.typeComptes.sort((a,b)=>a.value.localeCompare(b.value))
                }
            });

        });

    }

    ngAfterViewInit() {
        // 0042547 - fix IE
        if (this.browserUtil.isIE() && !this.registerAccount.profil) {
           this.registerAccount.profil = this.DEMANDEUR;
        }
    }

    register() {
        if (this.registerAccount.password !== this.confirmPassword) {
            this.doNotMatch = 'ERROR';
        } else {
            this.doNotMatch = null;
            this.error = null;
            this.errorUserExists = null;
            this.errorEmailExists = null;
            this.registerAccount.langKey = 'fr';
            // Gestion du profil
            this.registerAccount.authorities = [this.registerAccount.profil];
            this.registerService.save(this.registerAccount).subscribe(() => {
                this.success = true;

            });
        }
    }

    openLogin() {
        this.modalRef = this.loginModalService.open();
    }

}
