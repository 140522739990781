import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {MedimedUtilisateurDmModule} from './utilisateur-dm/utilisateur-dm.module';
import {MedimedDossierModule} from './dossier/dossier.module';
import {MedimedAttributDossierModule} from './attribut-dossier/attribut-dossier.module';
import {MedimedPieceJointeModule} from './piece-jointe/piece-jointe.module';
import {MedimedDocumentHorsDossierModule} from './document-hors-dossier/document-hors-dossier.module';
import {ListeReferenceService} from '../shared/liste-reference/liste-reference.service';

/* jhipster-needle-add-entity-module-import - JHipster will add entity modules imports here */

@NgModule({
    imports: [
        MedimedUtilisateurDmModule,
        MedimedDossierModule,
        MedimedAttributDossierModule,
        MedimedPieceJointeModule,
        MedimedDocumentHorsDossierModule,

        /* jhipster-needle-add-entity-module - JHipster will add entity modules here */
    ],
    declarations: [],
    entryComponents: [],
    providers: [
        ListeReferenceService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedEntityModule {}
