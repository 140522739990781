import {Component, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../shared/user/user.model';
@Injectable()
export class DelegationPopupService {
    private isOpen = false;

    constructor(
        private modalService: NgbModal,
        private router: Router
    ) {}

    open(component: any, user?: User | any): NgbModalRef {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        return this.modalRef(component, user);
    }

    modalRef(component: any, user: User): NgbModalRef {
        const modalRef = this.modalService.open(component, {size: 'lg', backdrop: 'static'});
        modalRef.componentInstance.user = user;
        modalRef.result.then((result) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true});
            this.isOpen = false;
        }, (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true});
            this.isOpen = false;
        });
        return modalRef;
    }
}
