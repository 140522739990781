/**
 * Class représentant un Fichier blob + nom de fichier
 */
export class Fichier {
    /** Nom du fichier */
    public name?: string;
    /** Fichier */
    public blob?: Blob;

    constructor(name: string, blob: Blob) {
        this.name = name;
        this.blob = blob;
    }
}
