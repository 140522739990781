import { NgModule, Sanitizer } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { JhiAlertService } from 'ng-jhipster';
import {
    MedimedSharedLibsModule,
    DmAlertComponent,
    DmAlertErrorComponent
} from './';

export function alertServiceProvider(sanitizer: Sanitizer) {
    // set below to true to make alerts look like toast
    const isToast = false;
    //return new JhiAlertService(sanitizer, isToast);
}

@NgModule({
    imports: [
        MedimedSharedLibsModule
    ],
    declarations: [
        DmAlertComponent,
        DmAlertErrorComponent
    ],
    providers: [
        {
            provide: JhiAlertService,
            useClass: JhiAlertService
        },
        Title
    ],
    exports: [
        MedimedSharedLibsModule,
        DmAlertComponent,
        DmAlertErrorComponent
    ]
})
export class MedimedSharedCommonModule {}
