import {Routes} from '@angular/router';

import {UserRouteAccessService} from '../../shared';
import {PieceJointeDeletePopupComponent} from './piece-jointe-delete-dialog.component';
import {Account} from '../../shared/user/account.model';

export const pieceJointeRoute: Routes = [
];

export const pieceJointePopupRoute: Routes = [
    {
        path: 'piece-jointe/:id/delete',
        component: PieceJointeDeletePopupComponent,
        data: {
            authorities: [Account.DEMANDEUR, Account.CONSULTANT, Account.ADMINISTRATEUR],
            pageTitle: 'PieceJointes'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
