import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MedimedSharedModule} from '../../shared';
import {
    PieceJointeDeleteDialogComponent,
    PieceJointeDeletePopupComponent,
    pieceJointePopupRoute,
    PieceJointePopupService,
    pieceJointeRoute,
    PieceJointeService
} from './';

const ENTITY_STATES = [
    ...pieceJointeRoute,
    ...pieceJointePopupRoute,
];

@NgModule({
    imports: [
        MedimedSharedModule,
        RouterModule.forRoot(ENTITY_STATES, { useHash: true })
    ],
    declarations: [
        PieceJointeDeleteDialogComponent,
        PieceJointeDeletePopupComponent
    ],
    entryComponents: [
        PieceJointeDeleteDialogComponent,
        PieceJointeDeletePopupComponent,
    ],
    providers: [
        PieceJointeService,
        PieceJointePopupService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedPieceJointeModule {}
