import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MedimedSharedModule} from '../shared';
import {
    adminState,
    AuditsComponent,
    AuditsService,
    DmConfigurationComponent,
    DmConfigurationService,
    DmDocsComponent,
    DmHealthCheckComponent,
    DmHealthModalComponent,
    DmHealthService,
    DmMetricsMonitoringComponent,
    DmMetricsMonitoringModalComponent,
    DmMetricsService,
    LogsComponent,
    LogsService,
    UserDeleteDialogComponent,
    UserDialogComponent,
    UserMgmtComponent,
    UserMgmtDeleteDialogComponent,
    UserMgmtDetailComponent,
    UserMgmtDialogComponent,
    UserModalService,
    UserResolve,
    UserResolvePagingParams
} from './';
import { UserFilterPipe } from './user-management/user-management.pipe';
/* jhipster-needle-add-admin-module-import - JHipster will add admin modules imports here */

@NgModule({
    imports: [
        MedimedSharedModule,
        RouterModule.forRoot(adminState, {useHash: true}),
        /* jhipster-needle-add-admin-module - JHipster will add admin modules here */
    ],
    declarations: [
        AuditsComponent,
        UserMgmtComponent,
        UserDialogComponent,
        UserDeleteDialogComponent,
        UserMgmtDetailComponent,
        UserMgmtDialogComponent,
        UserMgmtDeleteDialogComponent,
        LogsComponent,
        DmConfigurationComponent,
        DmHealthCheckComponent,
        DmHealthModalComponent,
        DmDocsComponent,
        DmMetricsMonitoringComponent,
        DmMetricsMonitoringModalComponent,
        UserFilterPipe,

    ],
    entryComponents: [
        UserMgmtDialogComponent,
        UserMgmtDeleteDialogComponent,
        DmHealthModalComponent,
        DmMetricsMonitoringModalComponent,
    ],
    providers: [
        AuditsService,
        DmConfigurationService,
        DmHealthService,
        DmMetricsService,
        LogsService,
        UserResolvePagingParams,
        UserResolve,
        UserModalService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MedimedAdminModule {}
