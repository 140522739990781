import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../shared';
import {PasswordComponent} from './password.component';
import {Account} from '../../shared/user/account.model';

export const passwordRoute: Route = {
    path: 'password',
    component: PasswordComponent,
    data: {
        authorities: Account.CONNECTE,
        pageTitle: 'Gestion du mot de passe'
    },
    canActivate: [UserRouteAccessService]
};
