import {Injectable} from '@angular/core';

/**
 * Service ayant pour but de stocker la collection d'alertError
 */
@Injectable()
export class AlertErrorService {

    errors: any[];

    constructor() {
        this.errors = [];
    }

    /**
     * Purge la liste d'erreurs affichées
     */
    clear() {
        this.errors.length = 0;
    }

}
