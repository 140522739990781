import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {JhiDateUtils} from 'ng-jhipster';

import {Dossier, EtatDossier} from './dossier.model';
import {createRequestOption, ResponseWrapper} from '../../shared';
import {Fichier} from '../../shared/file/file.model';
import {map} from "rxjs/internal/operators";

@Injectable()
export class DossierService {

    private resourceUrl = 'api/dossiers';

    constructor(private http: HttpClient, private dateUtils: JhiDateUtils) {
    }

    init(dossier: Dossier): Observable<Dossier> {
        const copy = this.convert(dossier);
        return this.http.post<Dossier>(`${this.resourceUrl}/init`, copy);
    }

    update(dossier: Dossier): Observable<Dossier> {
        return this.http.put<Dossier>(this.resourceUrl, dossier).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    completer(dossier: Dossier): Observable<Dossier> {
        return this.http.put<Dossier>(`${this.resourceUrl}/completer`, dossier).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    transmettre(dossier: Dossier): Observable<Dossier> {
        return this.http.put<Dossier>(`${this.resourceUrl}/transmettre`, dossier).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    refuser(dossier: Dossier): Observable<Dossier> {
        return this.http.post<Dossier>(`${this.resourceUrl}/refuser`, dossier).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    demanderInfos(dossier: Dossier): Observable<Dossier> {
        return this.http.put<Dossier>(`${this.resourceUrl}/demande-infos`, dossier)
            .pipe(map((dossier: Dossier) => {
                this.convertItemFromServer(dossier);
                return dossier;
            }));
    }

    arInfos(dossier: Dossier): Observable<Dossier> {
        return this.http.put(`${this.resourceUrl}/ar-infos`, dossier)
            .pipe(map((dossier: Dossier) => {
                this.convertItemFromServer(dossier);
                return dossier;
            }));
    }

    create(dossier: Dossier): Observable<Dossier> {
        const copy = this.convert(dossier);
        return this.http.post(this.resourceUrl, copy).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    /**
     * Met à jour la nature ainsi que la liste des attributs et des pièces jointes correspondant à la nouvelle nature
     * @param {Dossier} dossier
     * @returns {Observable<Dossier>}
     */
    updateNature(dossier: Dossier): Observable<Dossier> {
        return this.http.put(`${this.resourceUrl}/change-nature`, dossier).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    dupliquer(dossier: Dossier): Observable<Dossier> {
        return this.http.post(`${this.resourceUrl}/dupliquer`, dossier).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    changerEtat(id: number, etat: EtatDossier): Observable<Dossier> {
        return this.http.get(`${this.resourceUrl}/${id}/${EtatDossier[etat]}`).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    archiver(dossier: Dossier): Observable<Dossier> {
        return this.http.put(`${this.resourceUrl}/archiver`, dossier).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    desarchiver(id: number): Observable<Dossier> {
        return this.http.delete(`${this.resourceUrl}/${id}/desarchiver`).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    find(id: number): Observable<Dossier> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }

    findAll(): Observable<Dossier[]> {
        return this.http.get(`${this.resourceUrl}`).pipe(map((dossiers: Dossier[]) => {
            dossiers.forEach(dossier => this.convertItemFromServer(dossier));
            return dossiers;
        }));
    }

    query(req?: any): Observable<ResponseWrapper> {
        const params = createRequestOption(req);
        return this.http.get(this.resourceUrl, {params}).pipe(map((resp: HttpResponse<any>) => {
            return this.convertResponse(resp);
        }));
    }

    delete(id: number): Observable<Response> {
        return this.http.delete<Response>(`${this.resourceUrl}/${id}`);
    }

    /**
     * Exporte un fichier en PDF
     * @param id
     * @returns {Observable<R>}
     */
    exportPdf(id): Observable<Fichier> {
        return this.http.get(`${this.resourceUrl}/${id}/export`, {observe: 'response', responseType: 'blob'})
            .map((reponse) => {
                return new Fichier(reponse.headers.get('File-Name'), new Blob([reponse.body]));
            });
    }

    getPieceJointes(id: number): Observable<ResponseWrapper> {
        return this.http.get(`${this.resourceUrl}/${id}/piece-jointes`)
            .map((resp: HttpResponse<any>) => {
                return this.convertResponse(resp);
            });
    }

    private convertResponse(res: HttpResponse<any>): ResponseWrapper {
        return new ResponseWrapper(res.headers, res);
    }

    private convertItemFromServer(entity: any) {
        entity.dateArchivage = this.dateUtils
            .convertDateTimeFromServer(entity.dateArchivage);
        entity.dateCreation = this.dateUtils
            .convertDateTimeFromServer(entity.dateCreation);
        entity.dateDepot = this.dateUtils
            .convertDateTimeFromServer(entity.dateDepot);
        entity.dateFinPriseEnCharge = this.dateUtils
            .convertDateTimeFromServer(entity.dateFinPriseEnCharge);
        entity.dateModification = this.dateUtils
            .convertDateTimeFromServer(entity.dateModification);
    }

    public convert(dossier: Dossier): Dossier {
        const copy: Dossier = Object.assign({}, dossier);
        copy.dateArchivage = this.dateUtils.toDate(dossier.dateArchivage);
        copy.dateValidation = this.dateUtils.toDate(dossier.dateValidation);
        copy.dateRefus = this.dateUtils.toDate(dossier.dateRefus);
        copy.dateAccuseReception = this.dateUtils.toDate(dossier.dateAccuseReception);
        copy.dateDemandeInfoComplementaire = this.dateUtils.toDate(dossier.dateDemandeInfoComplementaire);
        copy.dateCreation = this.dateUtils.toDate(dossier.dateCreation);
        copy.dateDepot = this.dateUtils.toDate(dossier.dateDepot);
        copy.dateFinPriseEnCharge = this.dateUtils.toDate(dossier.dateFinPriseEnCharge);
        copy.dateModification = this.dateUtils.toDate(dossier.dateModification);
        return copy;
    }

    public genererAR(id: number): Observable<Dossier> {
        console.log(id);
        return this.http.post(`${this.resourceUrl}/genererAccuseReception`, id).pipe(map((dossier: Dossier) => {
            this.convertItemFromServer(dossier);
            return dossier;
        }));
    }
}
