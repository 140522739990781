import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JhiAlertService, JhiEventManager, JhiPaginationUtil, JhiParseLinks} from 'ng-jhipster';

import {ITEMS_PER_PAGE, Principal, ResponseWrapper, User, UserService} from '../../shared';
import {PaginationConfig} from '../../blocks/config/uib-pagination.config';
import {UtilisateurDm} from '../../entities/utilisateur-dm/utilisateur-dm.model';
import {Pageable} from '../../shared/util/pageable.model';

@Component({
    selector: 'dm-user-mgmt',
    templateUrl: './user-management.component.html'
})
export class UserMgmtComponent implements OnInit, OnDestroy {

    baseSortDm = true;
    baseSort = true;
    currentAccount: any;
    users: User[];
    error: any;
    success: any;
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    userFiltre: User;
    validatedUniquement: boolean;
    pageable: Pageable;

    constructor(private userService: UserService,
                private parseLinks: JhiParseLinks,
                private alertService: JhiAlertService,
                private principal: Principal,
                private eventManager: JhiEventManager,
                private paginationUtil: JhiPaginationUtil,
                private paginationConfig: PaginationConfig,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        this.userFiltre = new User();
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.userFiltre.activated = data['pagingParams'].aValider === 'true';
            this.page = data['pagingParams'].page;
            this.previousPage = data['pagingParams'].page;
            this.reverse = data['pagingParams'].ascending;
            this.predicate = data['pagingParams'].predicate;
        });
        this.pageable = {
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            totalSize: 0
        };
    }

    pageChange($event) {
        this.pageable.page = $event;
    }

    tabSort(attr: string, attr2: string) {
        if (this.baseSort) {
            this.users.sort((dhd1, dhd2) => {
                const attribut1 = !attr2 ? dhd1[attr] : dhd1[attr][attr2];
                const attribut2 = !attr2 ? dhd2[attr] : dhd2[attr][attr2];

                if (attribut1 && attribut2 && attribut1.toUpperCase() < attribut2.toUpperCase()) {
                    return -1;
                } else if (attribut1 && attribut2 && attribut1.toUpperCase() > attribut2.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
            this.baseSort = false;
        } else if (!this.baseSort) {
            this.users.reverse();
            this.baseSort = true;
        }
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
            this.currentAccount = account;
            this.loadAll();
            this.registerChangeInUsers();
        });
        this.userFiltre.utilisateurDm = new UtilisateurDm();
    }

    ngOnDestroy() {
        this.routeData.unsubscribe();
    }

    registerChangeInUsers() {
        this.eventManager.subscribe('userListModification', (response) => this.loadAll());
    }

    setActive(user, isActivated) {
        user.activated = isActivated;
        this.saveUser(user);
    }

    saveUser(user: User) {
        this.userService.update(user).subscribe(
            (response) => {
                if (response.status === 200) {
                    this.error = null;
                    this.success = 'OK';
                    this.loadAll();
                } else {
                    this.success = null;
                    this.error = 'ERROR';
                }
            });
    }

    setHandicapActive(user, isHandicap) {
        user.utilisateurDm.membreHandicap = isHandicap;
        this.saveUser(user);
    }

    loadAll() {
        this.userService.query(

        ).subscribe(
            (res: ResponseWrapper) => this.onSuccess(res.json, res.headers),
            (res: ResponseWrapper) => this.onError(res.json)
        );
    }

    trackIdentity(index, item: User) {
        return item.id;
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        this.router.navigate(['/user-management'], {
            queryParams: {
                page: this.page,
                sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
            }
        });
        this.loadAll();
    }

    private onSuccess(users, headers) {
        //  this.links = this.parseLinks.parse(headers.get('link'));
        this.totalItems = users.length;
        this.queryCount = this.totalItems;
        this.users = users;
        this.pageable.totalSize = this.users.length;
    }

    private onError(error) {
        this.alertService.error(error.error, error.message, null);
    }

}
