import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Response} from '@angular/http';

import {Observable} from 'rxjs/Rx';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {AttributDossier} from './attribut-dossier.model';
import {AttributDossierPopupService} from './attribut-dossier-popup.service';
import {AttributDossierService} from './attribut-dossier.service';
import {Dossier, DossierService} from '../dossier';
import {ResponseWrapper} from '../../shared';

@Component({
    selector: 'dm-attribut-dossier-dialog',
    templateUrl: './attribut-dossier-dialog.component.html'
})
export class AttributDossierDialogComponent implements OnInit {

    attributDossier: AttributDossier;
    authorities: any[];
    isSaving: boolean;

    dossiers: Dossier[];

    constructor(
        public activeModal: NgbActiveModal,
        private alertService: JhiAlertService,
        private attributDossierService: AttributDossierService,
        private dossierService: DossierService,
        private eventManager: JhiEventManager
    ) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.authorities = ['DEMANDEUR', 'CONSULTANT', 'ADMINISTRATEUR'];
        this.dossierService.query()
            .subscribe((res: ResponseWrapper) => { this.dossiers = res.json; }, (res: ResponseWrapper) => this.onError(res.json));
    }
    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.attributDossier.id !== undefined) {
            this.subscribeToSaveResponse(
                this.attributDossierService.update(this.attributDossier));
        } else {
            this.subscribeToSaveResponse(
                this.attributDossierService.create(this.attributDossier));
        }
    }

    private subscribeToSaveResponse(result: Observable<AttributDossier>) {
        result.subscribe((res: AttributDossier) =>
            this.onSaveSuccess(res), (res: Response) => this.onSaveError(res));
    }

    private onSaveSuccess(result: AttributDossier) {
        this.eventManager.broadcast({ name: 'attributDossierListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError(error) {
        try {
            error.json();
        } catch (exception) {
            error.message = error.text();
        }
        this.isSaving = false;
        this.onError(error);
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }

    trackDossierById(index: number, item: Dossier) {
        return item.id;
    }
}

@Component({
    selector: 'dm-attribut-dossier-popup',
    template: ''
})
export class AttributDossierPopupComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private attributDossierPopupService: AttributDossierPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.modalRef = this.attributDossierPopupService
                    .open(AttributDossierDialogComponent, params['id']);
            } else {
                this.modalRef = this.attributDossierPopupService
                    .open(AttributDossierDialogComponent);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
